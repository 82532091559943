export const sampleKpi = {
    "vac": 302595,
    "construction_cost": 262501,
    "total_project_cost": 262501,
    "equity": 40094,
    "area_heated": 1797,
    "area_unheated": 395,
    "msacc_finished": 125.51,
    "msacc_unfinished": 69.03,
    "cost_finished": 225541,
    "cost_unfinished": 27267,
    "cost_extra": 9693,
    "acquisition_cost": 0,
    "other_cost": 0
}


export const sampleZoning3 = {
    "city": "Tulsa",
    "kpis": {
        "vac": 302595,
        "construction_cost": 262501,
        "total_project_cost": 262501,
        "equity": 40094,
        "area_heated": 1797,
        "area_unheated": 395,
        "msacc_finished": 125.51,
        "msacc_unfinished": 69.03,
        "cost_finished": 225541,
        "cost_unfinished": 27267,
        "cost_extra": 9693,
        "acquisition_cost": 0,
        "other_cost": 0
    },
    "parcel": {
        "longitude": -95.8847014,
        "latitude": 36.1128366,
        "parcel_area": 8876.867504072106,
        "maximum_footprint_area": 4157.787893725272,
        "minimum_setback_area": 4719.079610346835,
        "mnimum_open_space_area": 4000,
        "maximum_buildable_area": 4157.787893725272,
        "maximum_coverage_area": 4876.867504072106,
        "max_foundation_width": 63.423056484753666,
        "max_foundation_depth": 64.5717611722968,
        "lot_width": 73.42305648475367,
        "lot_depth": 119.57176117229679,
        "parcel_id": "23575932413930",
        "property_type": "Residential",
        "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
        "owner_address": "8214 E 34TH ST TULSA OK 74145"
    },
    "map": {
        "parcel_polygon": [
            [
                -95.8845264633056,
                36.11297210424722
            ],
            [
                -95.88452116816903,
                36.1126436637475
            ],
            [
                -95.8847761852092,
                36.11263450562286
            ],
            [
                -95.88477638609173,
                36.11273169319397
            ],
            [
                -95.88477686162315,
                36.11295993462123
            ],
            [
                -95.88466064710641,
                36.11296478062045
            ],
            [
                -95.884543376459,
                36.11297114034726
            ],
            [
                -95.8845264633056,
                36.11297210424722
            ]
        ],
        "setback_polygon": [
            [
                -95.88475958036962,
                36.11286526794387
            ],
            [
                -95.88465439533684,
                36.11286965402289
            ],
            [
                -95.88465252388609,
                36.11286974378146
            ],
            [
                -95.88454199508347,
                36.11287573789351
            ],
            [
                -95.88453912159316,
                36.11269750681099
            ],
            [
                -95.88475921511363,
                36.11268960285839
            ],
            [
                -95.88475930214979,
                36.11273171577625
            ],
            [
                -95.88475958036962,
                36.11286526794387
            ]
        ],
        "subdivisions": [
            {
                "id": 1,
                "kpis": {

                    "vac": 222595,
                    "construction_cost": 262501,
                    "total_project_cost": 262501,
                    "equity": 26094,
                    "area_heated": 1797,
                    "area_unheated": 395,
                    "msacc_finished": 125.51,
                    "msacc_unfinished": 69.03,
                    "cost_finished": 225541,
                    "cost_unfinished": 27267,
                    "cost_extra": 9693,
                    "acquisition_cost": 0,
                    "other_cost": 0
                },
                "parcel_polygon": [
                    [-95.8846490148961, 36.11280330493504],
                    [-95.88477653529178, 36.11280330493504],
                    [-95.88477686162315, 36.11295993462123],
                    [-95.88466064710641, 36.11296478062045],
                    [-95.8846490148961, 36.11296541144907],
                    [-95.8846490148961, 36.11280330493504]
                ],
                "setback_polygon": [
                    [-95.88465221106426, 36.11280735759789],
                    [-95.88477335544016, 36.11280735759789],
                    [-95.88477366545497, 36.11295615579977],
                    [-95.88466326166407, 36.11296075949903],
                    [-95.88465221106426, 36.112961358786215],
                    [-95.88465221106426, 36.11280735759789]
                ],
                "subdivision": "A",
                "parcel": {
                    "longitude": -95.8847014,
                    "latitude": 36.1128366,
                    "parcel_area": 4455.867504072106,
                    "maximum_footprint_area": 2100,
                    "minimum_setback_area": 2520,
                    "mnimum_open_space_area": 2500,
                    "maximum_buildable_area": 2400.787893725272,
                    "maximum_coverage_area": 4876.867504072106,
                    "max_foundation_width": 63.423056484753666,
                    "max_foundation_depth": 64.5717611722968,
                    "lot_width": 73.42305648475367,
                    "lot_depth": 119.57176117229679,
                    "parcel_id": "23575932413930",
                    "property_type": "Residential",
                    "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
                    "owner_address": "8214 E 34TH ST TULSA OK 74145"
                },
                "zoning": {
                    "zoning_code": "RS-3",
                    "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
                    "front_setback": "35 ft",
                    "side_setback": "5 ft",
                    "rear_setback": "20 ft",
                    "max_building_height": 35,
                    "structure_types": {
                        "single_household": {
                            "permit_type": "Permitted",
                            "building_type": {
                                "detached_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "patio_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "two_unit_townhouse": {
                                    "minimum_lot_area": "4500 sqft",
                                    "minimum_lot_area_per_unit": "4500 sqft",
                                    "minimum_lot_width": "30 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "multi_unit_townhouse": {
                                    "permit_type": "Prohibited"
                                },
                                "manufactured_housing_unit": {
                                    "minimum_lot_area": "12000 sqft",
                                    "minimum_lot_area_per_unit": "12000 sqft",
                                    "minimum_lot_width": "100 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "manufactured_housing_subdivision": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "two_households_single_lot": {
                            "permit_type": "Special Exception. Approval Required.",
                            "building_type": {
                                "duplex": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "3450 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "minimum_open_space_per_unit": "2500 sqft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "multiple_households_single_lot": {
                            "permit_type": "Prohibited",
                            "building_type": {
                                "cottage_house_development": {
                                    "permit_type": "Prohibited"
                                },
                                "multi_unit_house": {
                                    "permit_type": "Prohibited"
                                },
                                "apartment_condo": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home_park": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "group_living": {
                            "permit_type": null,
                            "minimum_lot_area": "12000 sqft",
                            "minimum_lot_area_per_unit": "12000 sqft",
                            "minimum_lot_width": "100 ft",
                            "building_type": {
                                "assisted_living_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "community_group_home": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "convent_monastery_novitiate": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "elderly_retirement center": {
                                    "permit_type": "Prohibited"
                                },
                                "fraternity_sorority": {
                                    "permit_type": "Prohibited"
                                },
                                "homeless_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "life_care_retirement_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "re_entry_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "residential_treatment_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "rooming_boarding_house": {
                                    "permit_type": "Prohibited"
                                },
                                "shelter_emergency_protective": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "transitional_living_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                }
                            }
                        }
                    },
                    "minimum_street_frontage": "30 feet",
                    "minimum_other_street_setback": "25 feet"
                }
            },
            {
                "id": 2,
                "kpis": {

                    "vac": 202595,
                    "construction_cost": 262501,
                    "total_project_cost": 262501,
                    "equity": 22094,
                    "area_heated": 1797,
                    "area_unheated": 395,
                    "msacc_finished": 125.51,
                    "msacc_unfinished": 69.03,
                    "cost_finished": 225541,
                    "cost_unfinished": 27267,
                    "cost_extra": 9693,
                    "acquisition_cost": 0,
                    "other_cost": 0
                },
                "parcel_polygon": [
                    [-95.8846490148961, 36.11280330493504],
                    [-95.8846490148961, 36.11296541144907],
                    [-95.884543376459, 36.11297114034726],
                    [-95.8845264633056, 36.11297210424722],
                    [-95.88452374191341, 36.11280330493504],
                    [-95.8846490148961, 36.11280330493504]
                ],
                "setback_polygon": [
                    [-95.88464588307153, 36.11280752491784],
                    [-95.88464588307153, 36.11296152610617],
                    [-95.8845455265563, 36.112966968559455],
                    [-95.88452945906056, 36.112967884264414],
                    [-95.88452687373798, 36.11280752491784],
                    [-95.88464588307153, 36.11280752491784]
                ],
                "subdivision": "B",
                "parcel": {
                    "longitude": -95.8847014,
                    "latitude": 36.1128366,
                    "parcel_area": 4285.867504072106,
                    "maximum_footprint_area": 24157.787893725272,
                    "minimum_setback_area": 2719.079610346835,
                    "mnimum_open_space_area": 2100,
                    "maximum_buildable_area": 2157.787893725272,
                    "maximum_coverage_area": 2876.867504072106,
                    "max_foundation_width": 63.423056484753666,
                    "max_foundation_depth": 64.5717611722968,
                    "lot_width": 73.42305648475367,
                    "lot_depth": 119.57176117229679,
                    "parcel_id": "23575932413930",
                    "property_type": "Residential",
                    "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
                    "owner_address": "8214 E 34TH ST TULSA OK 74145"
                },
                "zoning": {
                    "zoning_code": "RS-3",
                    "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
                    "front_setback": "35 ft",
                    "side_setback": "5 ft",
                    "rear_setback": "20 ft",
                    "max_building_height": 35,
                    "structure_types": {
                        "single_household": {
                            "permit_type": "Permitted",
                            "building_type": {
                                "detached_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "patio_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "two_unit_townhouse": {
                                    "minimum_lot_area": "4500 sqft",
                                    "minimum_lot_area_per_unit": "4500 sqft",
                                    "minimum_lot_width": "30 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "multi_unit_townhouse": {
                                    "permit_type": "Prohibited"
                                },
                                "manufactured_housing_unit": {
                                    "minimum_lot_area": "12000 sqft",
                                    "minimum_lot_area_per_unit": "12000 sqft",
                                    "minimum_lot_width": "100 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "manufactured_housing_subdivision": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "two_households_single_lot": {
                            "permit_type": "Special Exception. Approval Required.",
                            "building_type": {
                                "duplex": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "3450 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "minimum_open_space_per_unit": "2500 sqft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "multiple_households_single_lot": {
                            "permit_type": "Prohibited",
                            "building_type": {
                                "cottage_house_development": {
                                    "permit_type": "Prohibited"
                                },
                                "multi_unit_house": {
                                    "permit_type": "Prohibited"
                                },
                                "apartment_condo": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home_park": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "group_living": {
                            "permit_type": null,
                            "minimum_lot_area": "12000 sqft",
                            "minimum_lot_area_per_unit": "12000 sqft",
                            "minimum_lot_width": "100 ft",
                            "building_type": {
                                "assisted_living_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "community_group_home": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "convent_monastery_novitiate": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "elderly_retirement center": {
                                    "permit_type": "Prohibited"
                                },
                                "fraternity_sorority": {
                                    "permit_type": "Prohibited"
                                },
                                "homeless_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "life_care_retirement_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "re_entry_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "residential_treatment_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "rooming_boarding_house": {
                                    "permit_type": "Prohibited"
                                },
                                "shelter_emergency_protective": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "transitional_living_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                }
                            }
                        }
                    },
                    "minimum_street_frontage": "30 feet",
                    "minimum_other_street_setback": "25 feet"
                }
            },
        ]


    },
    "zoning": {
        "zoning_code": "RS-3",
        "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
        "front_setback": "35 ft",
        "side_setback": "5 ft",
        "rear_setback": "20 ft",
        "max_building_height": 35,
        "structure_types": {
            "single_household": {
                "permit_type": "Permitted",
                "building_type": {
                    "detached_house": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "6900 sqft",
                        "minimum_lot_width": "60 ft",
                        "permit_type": "Permitted"
                    },
                    "patio_house": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "6900 sqft",
                        "minimum_lot_width": "60 ft",
                        "permit_type": "Permitted"
                    },
                    "two_unit_townhouse": {
                        "minimum_lot_area": "4500 sqft",
                        "minimum_lot_area_per_unit": "4500 sqft",
                        "minimum_lot_width": "30 ft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "multi_unit_townhouse": {
                        "permit_type": "Prohibited"
                    },
                    "manufactured_housing_unit": {
                        "minimum_lot_area": "12000 sqft",
                        "minimum_lot_area_per_unit": "12000 sqft",
                        "minimum_lot_width": "100 ft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "manufactured_housing_subdivision": {
                        "permit_type": "Prohibited"
                    },
                    "mobile_home": {
                        "permit_type": "Prohibited"
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "two_households_single_lot": {
                "permit_type": "Special Exception. Approval Required.",
                "building_type": {
                    "duplex": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "3450 sqft",
                        "minimum_lot_width": "60 ft",
                        "minimum_open_space_per_unit": "2500 sqft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "multiple_households_single_lot": {
                "permit_type": "Prohibited",
                "building_type": {
                    "cottage_house_development": {
                        "permit_type": "Prohibited"
                    },
                    "multi_unit_house": {
                        "permit_type": "Prohibited"
                    },
                    "apartment_condo": {
                        "permit_type": "Prohibited"
                    },
                    "mobile_home_park": {
                        "permit_type": "Prohibited"
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "group_living": {
                "permit_type": null,
                "minimum_lot_area": "12000 sqft",
                "minimum_lot_area_per_unit": "12000 sqft",
                "minimum_lot_width": "100 ft",
                "building_type": {
                    "assisted_living_facility": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "community_group_home": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "convent_monastery_novitiate": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "elderly_retirement center": {
                        "permit_type": "Prohibited"
                    },
                    "fraternity_sorority": {
                        "permit_type": "Prohibited"
                    },
                    "homeless_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "life_care_retirement_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "re_entry_facility": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "residential_treatment_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "rooming_boarding_house": {
                        "permit_type": "Prohibited"
                    },
                    "shelter_emergency_protective": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "transitional_living_center": {
                        "permit_type": "Special Exception. Approval Required."
                    }
                }
            }
        },
        "minimum_street_frontage": "30 feet",
        "minimum_other_street_setback": "25 feet"
    }
}


export const sampleZoning2 = {
    "city": "Tulsa",
    "kpis": {
        "vac": 302595,
        "construction_cost": 262501,
        "total_project_cost": 262501,
        "equity": 40094,
        "area_heated": 1797,
        "area_unheated": 395,
        "msacc_finished": 125.51,
        "msacc_unfinished": 69.03,
        "cost_finished": 225541,
        "cost_unfinished": 27267,
        "cost_extra": 9693,
        "acquisition_cost": 0,
        "other_cost": 0
    },
    "parcel": {
        "longitude": -95.8847014,
        "latitude": 36.1128366,
        "parcel_area": 8876.867504072106,
        "maximum_footprint_area": 4157.787893725272,
        "minimum_setback_area": 4501.079610346835,
        "mnimum_open_space_area": 4000,
        "maximum_buildable_area": 4157.787893725272,
        "maximum_coverage_area": 4876.867504072106,
        "max_foundation_width": 63.423056484753666,
        "max_foundation_depth": 64.5717611722968,
        "lot_width": 73.42305648475367,
        "lot_depth": 119.57176117229679,
        "parcel_id": "23575932413930",
        "property_type": "Residential",
        "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
        "owner_address": "8214 E 34TH ST TULSA OK 74145"
    },
    "map": {
        "parcel_polygon": [
            [
                -95.8845264633056,
                36.11297210424722
            ],
            [
                -95.88452116816903,
                36.1126436637475
            ],
            [
                -95.8847761852092,
                36.11263450562286
            ],
            [
                -95.88477638609173,
                36.11273169319397
            ],
            [
                -95.88477686162315,
                36.11295993462123
            ],
            [
                -95.88466064710641,
                36.11296478062045
            ],
            [
                -95.884543376459,
                36.11297114034726
            ],
            [
                -95.8845264633056,
                36.11297210424722
            ]
        ],
        "setback_polygon": [
            [
                -95.88475958036962,
                36.11286526794387
            ],
            [
                -95.88465439533684,
                36.11286965402289
            ],
            [
                -95.88465252388609,
                36.11286974378146
            ],
            [
                -95.88454199508347,
                36.11287573789351
            ],
            [
                -95.88453912159316,
                36.11269750681099
            ],
            [
                -95.88475921511363,
                36.11268960285839
            ],
            [
                -95.88475930214979,
                36.11273171577625
            ],
            [
                -95.88475958036962,
                36.11286526794387
            ]
        ],
        "subdivisions": [
            {
                "id": 1,
                "kpis": {

                    "vac": 222595,
                    "construction_cost": 262501,
                    "total_project_cost": 262501,
                    "equity": 26094,
                    "area_heated": 1797,
                    "area_unheated": 395,
                    "msacc_finished": 125.51,
                    "msacc_unfinished": 69.03,
                    "cost_finished": 225541,
                    "cost_unfinished": 27267,
                    "cost_extra": 9693,
                    "acquisition_cost": 0,
                    "other_cost": 0
                },
                "parcel_polygon": [
                    [-95.8846490148961, 36.11280330493504],
                    [-95.88477653529178, 36.11280330493504],
                    [-95.88477686162315, 36.11295993462123],
                    [-95.88466064710641, 36.11296478062045],
                    [-95.8846490148961, 36.11296541144907],
                    [-95.8846490148961, 36.11280330493504]
                ],
                "setback_polygon": [
                    [-95.88465221106426, 36.11280735759789],
                    [-95.88477335544016, 36.11280735759789],
                    [-95.88477366545497, 36.11295615579977],
                    [-95.88466326166407, 36.11296075949903],
                    [-95.88465221106426, 36.112961358786215],
                    [-95.88465221106426, 36.11280735759789]
                ],
                "subdivision": "A",
                "parcel": {
                    "longitude": -95.8847014,
                    "latitude": 36.1128366,
                    "parcel_area": 4455.867504072106,
                    "maximum_footprint_area": 2100,
                    "minimum_setback_area": 2520,
                    "mnimum_open_space_area": 2500,
                    "maximum_buildable_area": 2400.787893725272,
                    "maximum_coverage_area": 4876.867504072106,
                    "max_foundation_width": 63.423056484753666,
                    "max_foundation_depth": 64.5717611722968,
                    "lot_width": 73.42305648475367,
                    "lot_depth": 119.57176117229679,
                    "parcel_id": "23575932413930",
                    "property_type": "Residential",
                    "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
                    "owner_address": "8214 E 34TH ST TULSA OK 74145"
                },
                "zoning": {
                    "zoning_code": "RS-2",
                    "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
                    "front_setback": "34 ft",
                    "side_setback": "5 ft",
                    "rear_setback": "20 ft",
                    "max_building_height": 35,
                    "structure_types": {
                        "single_household": {
                            "permit_type": "Permitted",
                            "building_type": {
                                "detached_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "patio_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "two_unit_townhouse": {
                                    "minimum_lot_area": "4500 sqft",
                                    "minimum_lot_area_per_unit": "4500 sqft",
                                    "minimum_lot_width": "30 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "multi_unit_townhouse": {
                                    "permit_type": "Prohibited"
                                },
                                "manufactured_housing_unit": {
                                    "minimum_lot_area": "12000 sqft",
                                    "minimum_lot_area_per_unit": "12000 sqft",
                                    "minimum_lot_width": "100 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "manufactured_housing_subdivision": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "two_households_single_lot": {
                            "permit_type": "Special Exception. Approval Required.",
                            "building_type": {
                                "duplex": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "3450 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "minimum_open_space_per_unit": "2500 sqft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "multiple_households_single_lot": {
                            "permit_type": "Prohibited",
                            "building_type": {
                                "cottage_house_development": {
                                    "permit_type": "Prohibited"
                                },
                                "multi_unit_house": {
                                    "permit_type": "Prohibited"
                                },
                                "apartment_condo": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home_park": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "group_living": {
                            "permit_type": null,
                            "minimum_lot_area": "12000 sqft",
                            "minimum_lot_area_per_unit": "12000 sqft",
                            "minimum_lot_width": "100 ft",
                            "building_type": {
                                "assisted_living_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "community_group_home": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "convent_monastery_novitiate": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "elderly_retirement center": {
                                    "permit_type": "Prohibited"
                                },
                                "fraternity_sorority": {
                                    "permit_type": "Prohibited"
                                },
                                "homeless_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "life_care_retirement_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "re_entry_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "residential_treatment_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "rooming_boarding_house": {
                                    "permit_type": "Prohibited"
                                },
                                "shelter_emergency_protective": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "transitional_living_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                }
                            }
                        }
                    },
                    "minimum_street_frontage": "30 feet",
                    "minimum_other_street_setback": "25 feet"
                }
            },
            {
                "id": 2,
                "kpis": {

                    "vac": 202595,
                    "construction_cost": 262501,
                    "total_project_cost": 262501,
                    "equity": 22094,
                    "area_heated": 1797,
                    "area_unheated": 395,
                    "msacc_finished": 125.51,
                    "msacc_unfinished": 69.03,
                    "cost_finished": 225541,
                    "cost_unfinished": 27267,
                    "cost_extra": 9693,
                    "acquisition_cost": 0,
                    "other_cost": 0
                },
                "parcel_polygon": [
                    [-95.8846490148961, 36.11280330493504],
                    [-95.8846490148961, 36.11296541144907],
                    [-95.884543376459, 36.11297114034726],
                    [-95.8845264633056, 36.11297210424722],
                    [-95.88452374191341, 36.11280330493504],
                    [-95.8846490148961, 36.11280330493504]
                ],
                "setback_polygon": [
                    [-95.88464588307153, 36.11280752491784],
                    [-95.88464588307153, 36.11296152610617],
                    [-95.8845455265563, 36.112966968559455],
                    [-95.88452945906056, 36.112967884264414],
                    [-95.88452687373798, 36.11280752491784],
                    [-95.88464588307153, 36.11280752491784]
                ],
                "subdivision": "B",
                "parcel": {
                    "longitude": -95.8847014,
                    "latitude": 36.1128366,
                    "parcel_area": 4285.867504072106,
                    "maximum_footprint_area": 24157.787893725272,
                    "minimum_setback_area": 2719.079610346835,
                    "mnimum_open_space_area": 2100,
                    "maximum_buildable_area": 2157.787893725272,
                    "maximum_coverage_area": 2876.867504072106,
                    "max_foundation_width": 63.423056484753666,
                    "max_foundation_depth": 64.5717611722968,
                    "lot_width": 73.42305648475367,
                    "lot_depth": 119.57176117229679,
                    "parcel_id": "23575932413930",
                    "property_type": "Residential",
                    "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
                    "owner_address": "8214 E 34TH ST TULSA OK 74145"
                },
                "zoning": {
                    "zoning_code": "RS-2",
                    "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
                    "front_setback": "35 ft",
                    "side_setback": "5 ft",
                    "rear_setback": "20 ft",
                    "max_building_height": 35,
                    "structure_types": {
                        "single_household": {
                            "permit_type": "Permitted",
                            "building_type": {
                                "detached_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "patio_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "two_unit_townhouse": {
                                    "minimum_lot_area": "4500 sqft",
                                    "minimum_lot_area_per_unit": "4500 sqft",
                                    "minimum_lot_width": "30 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "multi_unit_townhouse": {
                                    "permit_type": "Prohibited"
                                },
                                "manufactured_housing_unit": {
                                    "minimum_lot_area": "12000 sqft",
                                    "minimum_lot_area_per_unit": "12000 sqft",
                                    "minimum_lot_width": "100 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "manufactured_housing_subdivision": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "two_households_single_lot": {
                            "permit_type": "Special Exception. Approval Required.",
                            "building_type": {
                                "duplex": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "3450 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "minimum_open_space_per_unit": "2500 sqft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "multiple_households_single_lot": {
                            "permit_type": "Prohibited",
                            "building_type": {
                                "cottage_house_development": {
                                    "permit_type": "Prohibited"
                                },
                                "multi_unit_house": {
                                    "permit_type": "Prohibited"
                                },
                                "apartment_condo": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home_park": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "group_living": {
                            "permit_type": null,
                            "minimum_lot_area": "12000 sqft",
                            "minimum_lot_area_per_unit": "12000 sqft",
                            "minimum_lot_width": "100 ft",
                            "building_type": {
                                "assisted_living_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "community_group_home": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "convent_monastery_novitiate": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "elderly_retirement center": {
                                    "permit_type": "Prohibited"
                                },
                                "fraternity_sorority": {
                                    "permit_type": "Prohibited"
                                },
                                "homeless_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "life_care_retirement_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "re_entry_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "residential_treatment_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "rooming_boarding_house": {
                                    "permit_type": "Prohibited"
                                },
                                "shelter_emergency_protective": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "transitional_living_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                }
                            }
                        }
                    },
                    "minimum_street_frontage": "30 feet",
                    "minimum_other_street_setback": "25 feet"
                }
            },
        ]


    },
    "zoning": {
        "zoning_code": "RS-2",
        "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
        "front_setback": "35 ft",
        "side_setback": "5 ft",
        "rear_setback": "20 ft",
        "max_building_height": 35,
        "structure_types": {
            "single_household": {
                "permit_type": "Permitted",
                "building_type": {
                    "detached_house": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "6900 sqft",
                        "minimum_lot_width": "60 ft",
                        "permit_type": "Permitted"
                    },
                    "patio_house": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "6900 sqft",
                        "minimum_lot_width": "60 ft",
                        "permit_type": "Permitted"
                    },
                    "two_unit_townhouse": {
                        "minimum_lot_area": "4500 sqft",
                        "minimum_lot_area_per_unit": "4500 sqft",
                        "minimum_lot_width": "30 ft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "multi_unit_townhouse": {
                        "permit_type": "Prohibited"
                    },
                    "manufactured_housing_unit": {
                        "minimum_lot_area": "12000 sqft",
                        "minimum_lot_area_per_unit": "12000 sqft",
                        "minimum_lot_width": "100 ft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "manufactured_housing_subdivision": {
                        "permit_type": "Prohibited"
                    },
                    "mobile_home": {
                        "permit_type": "Prohibited"
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "two_households_single_lot": {
                "permit_type": "Special Exception. Approval Required.",
                "building_type": {
                    "duplex": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "3450 sqft",
                        "minimum_lot_width": "60 ft",
                        "minimum_open_space_per_unit": "2500 sqft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "multiple_households_single_lot": {
                "permit_type": "Prohibited",
                "building_type": {
                    "cottage_house_development": {
                        "permit_type": "Prohibited"
                    },
                    "multi_unit_house": {
                        "permit_type": "Prohibited"
                    },
                    "apartment_condo": {
                        "permit_type": "Prohibited"
                    },
                    "mobile_home_park": {
                        "permit_type": "Prohibited"
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "group_living": {
                "permit_type": null,
                "minimum_lot_area": "12000 sqft",
                "minimum_lot_area_per_unit": "12000 sqft",
                "minimum_lot_width": "100 ft",
                "building_type": {
                    "assisted_living_facility": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "community_group_home": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "convent_monastery_novitiate": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "elderly_retirement center": {
                        "permit_type": "Prohibited"
                    },
                    "fraternity_sorority": {
                        "permit_type": "Prohibited"
                    },
                    "homeless_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "life_care_retirement_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "re_entry_facility": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "residential_treatment_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "rooming_boarding_house": {
                        "permit_type": "Prohibited"
                    },
                    "shelter_emergency_protective": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "transitional_living_center": {
                        "permit_type": "Special Exception. Approval Required."
                    }
                }
            }
        },
        "minimum_street_frontage": "30 feet",
        "minimum_other_street_setback": "25 feet"
    }
}


export const sampleZoning1 = {
    "city": "Tulsa",
    "kpis": {
        "vac": 302595,
        "construction_cost": 262501,
        "total_project_cost": 262501,
        "equity": 40094,
        "area_heated": 1797,
        "area_unheated": 395,
        "msacc_finished": 125.51,
        "msacc_unfinished": 69.03,
        "cost_finished": 225541,
        "cost_unfinished": 27267,
        "cost_extra": 9693,
        "acquisition_cost": 0,
        "other_cost": 0
    },
    "parcel": {
        "longitude": -95.8847014,
        "latitude": 36.1128366,
        "parcel_area": 8876.867504072106,
        "maximum_footprint_area": 4157.787893725272,
        "minimum_setback_area": 4719.079610346835,
        "mnimum_open_space_area": 4000,
        "maximum_buildable_area": 4257.787893725272,
        "maximum_coverage_area": 4876.867504072106,
        "max_foundation_width": 63.423056484753666,
        "max_foundation_depth": 64.5717611722968,
        "lot_width": 73.42305648475367,
        "lot_depth": 119.57176117229679,
        "parcel_id": "23575932413930",
        "property_type": "Residential",
        "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
        "owner_address": "8214 E 34TH ST TULSA OK 74145"
    },
    "map": {
        "parcel_polygon": [
            [
                -95.8845264633056,
                36.11297210424722
            ],
            [
                -95.88452116816903,
                36.1126436637475
            ],
            [
                -95.8847761852092,
                36.11263450562286
            ],
            [
                -95.88477638609173,
                36.11273169319397
            ],
            [
                -95.88477686162315,
                36.11295993462123
            ],
            [
                -95.88466064710641,
                36.11296478062045
            ],
            [
                -95.884543376459,
                36.11297114034726
            ],
            [
                -95.8845264633056,
                36.11297210424722
            ]
        ],
        "setback_polygon": [
            [
                -95.88475958036962,
                36.11286526794387
            ],
            [
                -95.88465439533684,
                36.11286965402289
            ],
            [
                -95.88465252388609,
                36.11286974378146
            ],
            [
                -95.88454199508347,
                36.11287573789351
            ],
            [
                -95.88453912159316,
                36.11269750681099
            ],
            [
                -95.88475921511363,
                36.11268960285839
            ],
            [
                -95.88475930214979,
                36.11273171577625
            ],
            [
                -95.88475958036962,
                36.11286526794387
            ]
        ],
        "subdivisions": [
            {
                "id": 1,
                "kpis": {

                    "vac": 222595,
                    "construction_cost": 262501,
                    "total_project_cost": 262501,
                    "equity": 26094,
                    "area_heated": 1797,
                    "area_unheated": 395,
                    "msacc_finished": 125.51,
                    "msacc_unfinished": 69.03,
                    "cost_finished": 225541,
                    "cost_unfinished": 27267,
                    "cost_extra": 9693,
                    "acquisition_cost": 0,
                    "other_cost": 0
                },
                "parcel_polygon": [
                    [-95.8846490148961, 36.11280330493504],
                    [-95.88477653529178, 36.11280330493504],
                    [-95.88477686162315, 36.11295993462123],
                    [-95.88466064710641, 36.11296478062045],
                    [-95.8846490148961, 36.11296541144907],
                    [-95.8846490148961, 36.11280330493504]
                ],
                "setback_polygon": [
                    [-95.88465221106426, 36.11280735759789],
                    [-95.88477335544016, 36.11280735759789],
                    [-95.88477366545497, 36.11295615579977],
                    [-95.88466326166407, 36.11296075949903],
                    [-95.88465221106426, 36.112961358786215],
                    [-95.88465221106426, 36.11280735759789]
                ],
                "subdivision": "A",
                "parcel": {
                    "longitude": -95.8847014,
                    "latitude": 36.1128366,
                    "parcel_area": 4455.867504072106,
                    "maximum_footprint_area": 2100,
                    "minimum_setback_area": 2520,
                    "mnimum_open_space_area": 2500,
                    "maximum_buildable_area": 2400.787893725272,
                    "maximum_coverage_area": 4876.867504072106,
                    "max_foundation_width": 63.423056484753666,
                    "max_foundation_depth": 64.5717611722968,
                    "lot_width": 73.42305648475367,
                    "lot_depth": 119.57176117229679,
                    "parcel_id": "23575932413930",
                    "property_type": "Residential",
                    "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
                    "owner_address": "8214 E 34TH ST TULSA OK 74145"
                },
                "zoning": {
                    "zoning_code": "RS-1",
                    "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
                    "front_setback": "35 ft",
                    "side_setback": "5 ft",
                    "rear_setback": "20 ft",
                    "max_building_height": 35,
                    "structure_types": {
                        "single_household": {
                            "permit_type": "Permitted",
                            "building_type": {
                                "detached_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "patio_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "two_unit_townhouse": {
                                    "minimum_lot_area": "4500 sqft",
                                    "minimum_lot_area_per_unit": "4500 sqft",
                                    "minimum_lot_width": "30 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "multi_unit_townhouse": {
                                    "permit_type": "Prohibited"
                                },
                                "manufactured_housing_unit": {
                                    "minimum_lot_area": "12000 sqft",
                                    "minimum_lot_area_per_unit": "12000 sqft",
                                    "minimum_lot_width": "100 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "manufactured_housing_subdivision": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "two_households_single_lot": {
                            "permit_type": "Special Exception. Approval Required.",
                            "building_type": {
                                "duplex": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "3450 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "minimum_open_space_per_unit": "2500 sqft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "multiple_households_single_lot": {
                            "permit_type": "Prohibited",
                            "building_type": {
                                "cottage_house_development": {
                                    "permit_type": "Prohibited"
                                },
                                "multi_unit_house": {
                                    "permit_type": "Prohibited"
                                },
                                "apartment_condo": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home_park": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "group_living": {
                            "permit_type": null,
                            "minimum_lot_area": "12000 sqft",
                            "minimum_lot_area_per_unit": "12000 sqft",
                            "minimum_lot_width": "100 ft",
                            "building_type": {
                                "assisted_living_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "community_group_home": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "convent_monastery_novitiate": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "elderly_retirement center": {
                                    "permit_type": "Prohibited"
                                },
                                "fraternity_sorority": {
                                    "permit_type": "Prohibited"
                                },
                                "homeless_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "life_care_retirement_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "re_entry_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "residential_treatment_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "rooming_boarding_house": {
                                    "permit_type": "Prohibited"
                                },
                                "shelter_emergency_protective": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "transitional_living_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                }
                            }
                        }
                    },
                    "minimum_street_frontage": "30 feet",
                    "minimum_other_street_setback": "25 feet"
                }
            },
            {
                "id": 2,
                "kpis": {

                    "vac": 202595,
                    "construction_cost": 262501,
                    "total_project_cost": 262501,
                    "equity": 22094,
                    "area_heated": 1797,
                    "area_unheated": 395,
                    "msacc_finished": 125.51,
                    "msacc_unfinished": 69.03,
                    "cost_finished": 225541,
                    "cost_unfinished": 27267,
                    "cost_extra": 9693,
                    "acquisition_cost": 0,
                    "other_cost": 0
                },
                "parcel_polygon": [
                    [-95.8846490148961, 36.11280330493504],
                    [-95.8846490148961, 36.11296541144907],
                    [-95.884543376459, 36.11297114034726],
                    [-95.8845264633056, 36.11297210424722],
                    [-95.88452374191341, 36.11280330493504],
                    [-95.8846490148961, 36.11280330493504]
                ],
                "setback_polygon": [
                    [-95.88464588307153, 36.11280752491784],
                    [-95.88464588307153, 36.11296152610617],
                    [-95.8845455265563, 36.112966968559455],
                    [-95.88452945906056, 36.112967884264414],
                    [-95.88452687373798, 36.11280752491784],
                    [-95.88464588307153, 36.11280752491784]
                ],
                "subdivision": "B",
                "parcel": {
                    "longitude": -95.8847014,
                    "latitude": 36.1128366,
                    "parcel_area": 4285.867504072106,
                    "maximum_footprint_area": 24157.787893725272,
                    "minimum_setback_area": 2719.079610346835,
                    "mnimum_open_space_area": 2100,
                    "maximum_buildable_area": 2157.787893725272,
                    "maximum_coverage_area": 2876.867504072106,
                    "max_foundation_width": 63.423056484753666,
                    "max_foundation_depth": 64.5717611722968,
                    "lot_width": 73.42305648475367,
                    "lot_depth": 119.57176117229679,
                    "parcel_id": "23575932413930",
                    "property_type": "Residential",
                    "owner_name": "BROWN, LAWRANCE E AND DEBBIE K",
                    "owner_address": "8214 E 34TH ST TULSA OK 74145"
                },
                "zoning": {
                    "zoning_code": "RS-1",
                    "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
                    "front_setback": "35 ft",
                    "side_setback": "5 ft",
                    "rear_setback": "20 ft",
                    "max_building_height": 35,
                    "structure_types": {
                        "single_household": {
                            "permit_type": "Permitted",
                            "building_type": {
                                "detached_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "patio_house": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "6900 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "permit_type": "Permitted"
                                },
                                "two_unit_townhouse": {
                                    "minimum_lot_area": "4500 sqft",
                                    "minimum_lot_area_per_unit": "4500 sqft",
                                    "minimum_lot_width": "30 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "multi_unit_townhouse": {
                                    "permit_type": "Prohibited"
                                },
                                "manufactured_housing_unit": {
                                    "minimum_lot_area": "12000 sqft",
                                    "minimum_lot_area_per_unit": "12000 sqft",
                                    "minimum_lot_width": "100 ft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "manufactured_housing_subdivision": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "two_households_single_lot": {
                            "permit_type": "Special Exception. Approval Required.",
                            "building_type": {
                                "duplex": {
                                    "minimum_lot_area": "6900 sqft",
                                    "minimum_lot_area_per_unit": "3450 sqft",
                                    "minimum_lot_width": "60 ft",
                                    "minimum_open_space_per_unit": "2500 sqft",
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "multiple_households_single_lot": {
                            "permit_type": "Prohibited",
                            "building_type": {
                                "cottage_house_development": {
                                    "permit_type": "Prohibited"
                                },
                                "multi_unit_house": {
                                    "permit_type": "Prohibited"
                                },
                                "apartment_condo": {
                                    "permit_type": "Prohibited"
                                },
                                "mobile_home_park": {
                                    "permit_type": "Prohibited"
                                },
                                "mixed_use_building": {
                                    "permit_type": "Prohibited"
                                },
                                "vertical_mixed_use_building": {
                                    "permit_type": "Prohibited"
                                }
                            }
                        },
                        "group_living": {
                            "permit_type": null,
                            "minimum_lot_area": "12000 sqft",
                            "minimum_lot_area_per_unit": "12000 sqft",
                            "minimum_lot_width": "100 ft",
                            "building_type": {
                                "assisted_living_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "community_group_home": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "convent_monastery_novitiate": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "elderly_retirement center": {
                                    "permit_type": "Prohibited"
                                },
                                "fraternity_sorority": {
                                    "permit_type": "Prohibited"
                                },
                                "homeless_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "life_care_retirement_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "re_entry_facility": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "residential_treatment_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "rooming_boarding_house": {
                                    "permit_type": "Prohibited"
                                },
                                "shelter_emergency_protective": {
                                    "permit_type": "Special Exception. Approval Required."
                                },
                                "transitional_living_center": {
                                    "permit_type": "Special Exception. Approval Required."
                                }
                            }
                        }
                    },
                    "minimum_street_frontage": "30 feet",
                    "minimum_other_street_setback": "25 feet"
                }
            },
        ]


    },
    "zoning": {
        "zoning_code": "RS-1",
        "reference": "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE",
        "front_setback": "35 ft",
        "side_setback": "5 ft",
        "rear_setback": "20 ft",
        "max_building_height": 35,
        "structure_types": {
            "single_household": {
                "permit_type": "Permitted",
                "building_type": {
                    "detached_house": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "6900 sqft",
                        "minimum_lot_width": "60 ft",
                        "permit_type": "Permitted"
                    },
                    "patio_house": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "6900 sqft",
                        "minimum_lot_width": "60 ft",
                        "permit_type": "Permitted"
                    },
                    "two_unit_townhouse": {
                        "minimum_lot_area": "4500 sqft",
                        "minimum_lot_area_per_unit": "4500 sqft",
                        "minimum_lot_width": "30 ft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "multi_unit_townhouse": {
                        "permit_type": "Prohibited"
                    },
                    "manufactured_housing_unit": {
                        "minimum_lot_area": "12000 sqft",
                        "minimum_lot_area_per_unit": "12000 sqft",
                        "minimum_lot_width": "100 ft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "manufactured_housing_subdivision": {
                        "permit_type": "Prohibited"
                    },
                    "mobile_home": {
                        "permit_type": "Prohibited"
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "two_households_single_lot": {
                "permit_type": "Special Exception. Approval Required.",
                "building_type": {
                    "duplex": {
                        "minimum_lot_area": "6900 sqft",
                        "minimum_lot_area_per_unit": "3450 sqft",
                        "minimum_lot_width": "60 ft",
                        "minimum_open_space_per_unit": "2500 sqft",
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "multiple_households_single_lot": {
                "permit_type": "Prohibited",
                "building_type": {
                    "cottage_house_development": {
                        "permit_type": "Prohibited"
                    },
                    "multi_unit_house": {
                        "permit_type": "Prohibited"
                    },
                    "apartment_condo": {
                        "permit_type": "Prohibited"
                    },
                    "mobile_home_park": {
                        "permit_type": "Prohibited"
                    },
                    "mixed_use_building": {
                        "permit_type": "Prohibited"
                    },
                    "vertical_mixed_use_building": {
                        "permit_type": "Prohibited"
                    }
                }
            },
            "group_living": {
                "permit_type": null,
                "minimum_lot_area": "12000 sqft",
                "minimum_lot_area_per_unit": "12000 sqft",
                "minimum_lot_width": "100 ft",
                "building_type": {
                    "assisted_living_facility": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "community_group_home": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "convent_monastery_novitiate": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "elderly_retirement center": {
                        "permit_type": "Prohibited"
                    },
                    "fraternity_sorority": {
                        "permit_type": "Prohibited"
                    },
                    "homeless_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "life_care_retirement_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "re_entry_facility": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "residential_treatment_center": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "rooming_boarding_house": {
                        "permit_type": "Prohibited"
                    },
                    "shelter_emergency_protective": {
                        "permit_type": "Special Exception. Approval Required."
                    },
                    "transitional_living_center": {
                        "permit_type": "Special Exception. Approval Required."
                    }
                }
            }
        },
        "minimum_street_frontage": "30 feet",
        "minimum_other_street_setback": "25 feet"
    }
}
