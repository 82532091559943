import React, { useContext } from 'react'
import './ActionSection.css'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../components/auth/AuthenticationContext';
import { LoadingSkeletonText } from '../../../../components/skeleton_loading/SkeletonLoading';

function ActionSection() {

  const navigate = useNavigate();
  const { user, loading } = useContext(AuthContext);

  return (
    <div className='lan_action_section'>
      <h1 className='iqland_motto' data-aos="fade-left">Land Intelligence done. <span >Fast.</span></h1>
      <p className='lan_normal_text' data-aos="fade-left">IQLAND equips real estate developers, agents, architects, and homeowners with cutting-edge tools to accurately estimate property values, navigate complex zoning regulations, and forecast project profitability—quickly and efficiently.</p>
      <p className='lan_normal_text' style={{color:"var(--primary-clr)", fontWeight:"bold"}} data-aos="zoom-in">7-day free trial</p>
      <div className='lan_action_section__buttons' data-aos="zoom-out">
        <div className='lan_secondary_button' onClick={() => navigate("/contact")}>Contact Us</div>
        {
            !loading ?
              <div
                className='lan_primary_button'
                onClick={() => user ? window.location.href = "/user/home": window.location.href = "/register" }>
                Get Started
              </div>
              :
              <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
          }
      </div>
      <img src="/images/lan_home/action_building.png" alt='action' data-aos="fade-left"/>
    </div>
  )
}

export default ActionSection