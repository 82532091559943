import React from 'react'
import "./NewChat.css"
import { preMessage } from '../data/PreMessage'

function NewChat({setPrePromptObj, prePromptObj }) {

    return (
        <div className='copilot_new_chat'>
            <div className='copilot_new_chat__logo_area'>
                <img style={{height:"3rem"}} src="/black_icon.svg" alt='iqland' />
                <h3 style={{color:"#3e3e3e"}}>Let's get started</h3>
            </div>
            <div className='copilot_new_chat__cards'>

                {
                    preMessage.map((item, index) => (
                        <div
                            style={prePromptObj?.id === item.id ? { backgroundColor: "#dadada" } : {}}
                            key={index}
                            className={"copilot_new_chat__card"}
                            onClick={() => setPrePromptObj(item)}>
                            <h6>{item.title}</h6>
                            <div>{item.text}</div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default NewChat