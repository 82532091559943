import React, { useEffect, useRef, useState } from 'react'
import './DashboardGuthrie.css'
import PlanNew from './component/PlanNew'
import MapWithPolygon from '../../../components/google_map/MapWithPolygon'
import ParcelDetails from './component/ParcelDetails'
import GoogleAddressInput from '../../../components/google_map/GoogleAddressInput'
import NewFilter from './component/NewFilter'
import IQList from '../../../components/util_component/iq_list/IQList'
import { createReport, getArchitecturePlansOnDashboard, getFavoritePlans, getParcelInfo, sendReportToEmail } from '../../../components/user_service/UserService'
import IQInformationPop from '../../../components/util_component/iq_information_pop/IQInformationPop'
import { LoadingSkeleton } from '../../../components/skeleton_loading/SkeletonLoading'
import { ArrowDropDown } from '@mui/icons-material'
import IQTextView from '../../../components/util_component/iq_text_view/IQTextView'
import OtherNav from '../../../components/other_nav/OtherNav'
import IQSpinner from '../../../components/util_component/iq_spinner/IQSpinner'
import { Alert, Snackbar } from '@mui/material'
import { useLocation } from 'react-router-dom'
import UserNavGuthrie from '../../../components/user_nav_guthrie/UserNavGuthrie'

function DashboardGuthrie() {

    // optional filter for kpi
    const [isOptional, setIsOptional] = useState(false);

   
    const [info, setInfo] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    const [mobileSideBar, setMobileSidebar] = useState(false);

    const handleMobileSidebar = () => {
        setMobileSidebar(!mobileSideBar);
    };


    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState("");
    const [toastStatus, setToastStatus] = useState("success");


    // parcel data
    // map
    const [mapData, setMapData] = useState();
    const [parcelData, setParcelData] = useState();
    const [zoningData, setZoningData] = useState();
    // parcel data loading
    const [parcelLoading, setParcelLoading] = useState(false);
    // no parcel
    const [noParcelFound, setNoParcelFound] = useState(false);



    // plans
    const [plans, setPlans] = useState();
    // plans loading
    const [plansLoading, setPlansLoading] = useState(false);
    // plans pagination
    const [plansPagination, setPlansPagination] = useState();
    // plans count
    const [plansCount, setPlansCount] = useState(20);
    // load more loading
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);


    // favoirte plans
    const [favoirtPlans, setFavoritePlans] = useState();

    // filter bar visibility
    const [filterBar, setFilterBar] = useState(false);
    const [showActions, setShowActions] = useState(false);

    // top floors
    const [floorCount, setFloorCount] = useState("");

    // top total area
    const [totalArea, setTotalArea] = useState("")


    // select plan state
    const [selectedPlan, setSelectedPlan] = useState({});

    // actions list
    const action = [
        {
            text: "Download the report",
            action: null
        },
        {
            text: "Share report to mail",
            action: () => sendReportMail()
        }

    ]

    // handle outside click
    const actionsRef = useRef(null);
    const handleClickOutside = (event) => {
        if (actionsRef.current && !actionsRef.current.contains(event.target)) {
            setShowActions(false);
        }
    };

    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);
    // end of handle outside click


    // get favorite plans
    async function getFavoritePlansOfUser() {
        try {
            const response = await getFavoritePlans();
            setFavoritePlans(response.data);
        }
        catch (err) {
            // console.log(err);
        }
    }

    // check id in the favorite list
    function isFavorite(plan_id) {
        return favoirtPlans.some(favPlan => favPlan.id === plan_id);
    }

    // check id is selected plan
    function isSelectedPlan(plan_id) {
        return selectedPlan.id === plan_id;
    }

    // main report creation based on address
    async function createReportFun(addr) {
        try {
            const response = await createReport(addr);
            // console.log("report", response);
            return response.data;
        }
        catch (err) {
            // console.log("report", err.message);
        }
    }

    async function fetchParcelInfo(addr, report_id) {
        setNoParcelFound(false)
        setParcelLoading(true)
        try {
            const response = await getParcelInfo(addr, report_id);
            // console.log("parcel", response);
            setMapData(response.data.map);
            setParcelData(response.data.parcel);
            setZoningData(response.data.zoning);
            setParcelLoading(false);
        }
        catch (err) {
            setNoParcelFound(true)
            setParcelLoading(false);
            // console.log("parcel", err);
        }
    }

 

    async function getPlans(data, report_id) {
        setPlansLoading(true)
        try {
            const response = await getArchitecturePlansOnDashboard(data, report_id);
            // console.log("plans", response);
            setPlans(response.data)
            setPlansPagination(response.pagination)
            setPlansLoading(false)
            return response.data;
        }
        catch (err) {
            // console.log("plans", err);
            setPlansLoading(false)
        }
    }

  

    async function handleLoadMore(){
        setLoadMoreLoading(true);
        setPlansCount(plansCount+20);
        const c = plansCount + 20;
        try {
            const re = await createReport(initAddress);
            const pl = await getArchitecturePlansOnDashboard({...filter, per_page:c}, re.id)
            // console.log("plans", pl);
            setPlans(pl.data)
            setLoadMoreLoading(false);
        }
        catch (err) {
            // console.log("plans", err);
            setLoadMoreLoading(false);
        }
    }

    // handle address
    const [address, setAddress] = useState("");
    const [initAddress, setInitAddress] = useState("8226 E 34TH ST, Tulsa, OK");

    // handle address search on top filter
    const handleAddressSearch = async () => {
        if (address !== null && address !== undefined && address !== "") {
            setParcelLoading(true);
  
            
            setInitAddress(address);
            setPlansLoading(true)
            try {
                const report = await createReportFun(address);
                await fetchParcelInfo(address, report.id);

                const updatedFilter = {
                    ...filter,
                    address: address
                };
                setFilter(updatedFilter);
                sessionStorage.setItem("filter", JSON.stringify(updatedFilter))

                await getPlans(updatedFilter, report.id).then((plans) => {
                    if (plans && plans.length !== 0) {
                        setSelectedPlan(plans[0]);
                    }
                    else {
                    }
                })
                // plans && plans.length > 0 ? await fetchKpi(report.id, buildQuality, parseFloat(acquisitionCost) + parseFloat(additionalCost), address, plans[0].id) : setKpiLoading(false);
                // console.log("handleAddressSearch Worked!")
                setAddress("")
            } catch (err) {
                // console.log("handleAddressSearch", err.message);
            }
        } else {
            setInfoMessage("Please enter address");
            setInfo(true);
        }
    }


    // handle press enter in the textbox
    const handleKeyPress = async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {

            event.preventDefault();
            await handleAddressSearch();

        }
    };

    // handle basement quality
    const [basement, setBasement] = useState("NO");

   
    // handle basement quality
    const [wallType, setWallType] = useState("Brick Veneer - Wood Frame");

  


    // handle build quality
    const [buildQuality, setBuildQuality] = useState("BRONZE");

   

    // handle Acquisition cost
    const [acquisitionCost, setAcquisitionCost] = useState(0);

    // handle Additional cost
    const [additionalCost, setAdditionalCost] = useState(0);





    // handle filter button click
    const handleFilterButtonClick = async (body) => {
        try {
            setPlansLoading(true)
            setFilterBar(false)
            setTotalArea(`${body.area_total_min}-${body.area_total_max}`)
            setFloorCount(body.stories.map((item) => item.toString()))
            const report_id = await createReportFun(initAddress);
            const res = await getPlans(body, report_id.id)

            sessionStorage.setItem("filter", JSON.stringify(body))

            if (res && res.length !== 0) {
                setSelectedPlan(res[0]);
            } else {
            }

        } catch (err) {
            setInfo(true);
            // console.log(err.message);
        }
    };


    //filter object
    const [filter, setFilter] = useState({
        "title": "",
        "address": initAddress,
        "architectural_style": [
            "Bungalow",
            "Craftsman"
        ],
        "area_total_min": 10,
        "area_total_max": 10000,
        "width_min": 10,
        "width_max": 500,
        "height_min": 10,
        "height_max": 100,
        "depth_min": 10,
        "depth_max": 500,
        "stories": ["1", "2", "3"],
        "cars_capacity": ["1", "2", "3"],
        "foundation": ["Basement", "Crawl", "Daylight", "Monolithic Slab", "Pier", "Piling", "Post Beam", "Stem Wall", "Walk out"],
        "exterior_wall_type": ['2x6', '2x4', '2x8', 'Block', 'Metal', 'ICF', 'Log', 'Block / CMU (main floor)', '2x4 or 2x6', '2x4 and 2x6', 'Block / CMU (main floor) or 2x6', '2x6 or Block / CMU (main floor)'],
        "garage_type": ["Attached", "Detached", "Carport", "Drive Under", "RV Garage", "None"],
        "units": ["Single Family", "Duplex", "Multi Family", "Other"],
        "bedrooms": ["1", "2", "3", "4", "5", "6", "7", "8+"],
        "bathrooms": ["1", "1.5", "2", "2.5", "3", "3.5", "4", "5+"]
    });


    // get address from user
    const getDataFromSessionStorage = async () => {
        let addr = "8226 E 34TH ST, Tulsa, OK"
        let mFilter = filter;
        let acquisition = acquisitionCost;
        let additional = additionalCost;
        let quality = buildQuality;
        let wall = wallType;
        let base = basement;
        let kpiOptional = isOptional;

        if (sessionStorage.getItem("isOptional") && sessionStorage.getItem("isOptional") !== "NA") {
            kpiOptional = sessionStorage.getItem("isOptional")
        }

        if (sessionStorage.getItem("basement") && sessionStorage.getItem("basement") !== "NA") {
            base = sessionStorage.getItem("basement")
        }

        if (sessionStorage.getItem("walltype") && sessionStorage.getItem("walltype") !== "NA") {
            wall = sessionStorage.getItem("walltype")
        }

        if (sessionStorage.getItem("address") && sessionStorage.getItem("address") !== "NA") {
            addr = sessionStorage.getItem("address")
        }
        if (sessionStorage.getItem("filter")) {
            mFilter = JSON.parse(sessionStorage.getItem("filter"))
        }
        if (sessionStorage.getItem("acquisitionCost")) {
            acquisition = sessionStorage.getItem("acquisitionCost")
        }
        if (sessionStorage.getItem("quality")) {
            quality = sessionStorage.getItem("quality")
        }
        if (sessionStorage.getItem("additional")) {
            additional = sessionStorage.getItem("additional")
        }
        return { addr, mFilter, acquisition, quality, additional, base, wall, kpiOptional };
    }

    const sendReportMail = () => {
        try {
            createReportFun(initAddress).then((report) => {
                // console.log(report.id)
                sendReportToEmail(report.id);
                // console.log("mail sent");

                setToastStatus("success")
                setToastText("Report sent")
                setToastShow(true)
            })
        }
        catch (err) {
            // console.log(err.message)
            setToastStatus("warning")
            setToastText("Somthing went wrong!")
            setToastShow(true)
        }
    }
    const location = useLocation();
    const [addressCopilot, setAddressCopilot] = useState(location.state?.address);

 


    useEffect(() => {
        

        try {
            getDataFromSessionStorage().then(({ addr, mFilter, acquisition, quality, additional, wall, base, kpiOptional }) => {
                const mAddress = addressCopilot?addressCopilot:"8226 E 34TH ST, Tulsa, OK"
                setInitAddress(mAddress);
                setIsOptional(kpiOptional === "true");
                setAcquisitionCost(acquisition);
                setAdditionalCost(additional);
                setBuildQuality(quality)

               

                setWallType(wall)
                setBasement(base)
                createReportFun(mAddress)
                    .then((report_id) => {

                        // getting parcel information zoning, map, parcel
                        fetchParcelInfo(mAddress, report_id.id);


                        //get user's favorite plan lists
                        getFavoritePlansOfUser();

                        const updatedFilter = {
                            ...mFilter,
                            address: mAddress
                        };
                        setFilter(updatedFilter);


                        //get plans
                        getPlans(updatedFilter, report_id.id).then((plans) => {
                            if (plans && plans.length !== 0) {
                                setSelectedPlan(plans[0]);
                                
                            }
                        })

                        setTotalArea(`${mFilter.area_total_min}-${mFilter.area_total_max}`)
                        setFloorCount(mFilter.stories.map((item) => item.toString()))
                    });

            })


        } catch (err) {

            setInfoMessage("Sorry, We didn't implemented for this city. Coming Soon.")
            setInfo(true);
            // console.log(err.message);
        }

    }, []);


    // handle plan change
    const handlePlanChange = async (plan_id) => {
        // console.log(plan_id)
        
    }

     //hide mobile other nav bar out side click
     useEffect(() => {
        const handleOutsideClick = (event) => {
            if (mobileSideBar && !event.target.closest('.other_nav')) {
                setMobileSidebar(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [mobileSideBar]);

    


    return (
        <div className='dashboard_tulsa' style={filterBar ? { overflowY: "hidden" } : { overflowY: "auto" }}>
            <div>
                <UserNavGuthrie tab={"d"} handleBurgerClick={handleMobileSidebar} />
            </div>
            <div className='dashboard_new__main'>

                {/* Dashboard top  filter */}
                <div className='dashboard_new__top_filter_area'>

                    <div className='dashboard_new__address_input'>
                        <GoogleAddressInput
                            setAddress={setAddress}
                            address={address}
                            handleKeyPress={handleKeyPress}
                        />
                        <div onClick={handleAddressSearch} className='keyword_search_btn'>
                            <img src='/images/dashboard_new/search_icon.svg' alt='filter' />
                        </div>
                    </div>


                    <div className='dashboard_new__top_filter'>

                        <IQTextView label={"Total Area (sqft)"} text={totalArea} handleClick={() => setFilterBar(true)} />
                        <IQTextView label={"Floors"} text={floorCount} handleClick={() => setFilterBar(true)} />

                        <div onClick={() => setFilterBar(true)} className='dashboard_new__more_filter_btn'>
                            <img src="/images/dashboard_new/more_filter_icon.svg" alt='more' />
                            <div>Filters</div>
                        </div>
                    </div>

                </div>



                {/* Address and action button */}
                <div className='dashboard_new__address_area'>

                    {/* address */}
                    <h6 className='dashboard_new_address'>{initAddress}</h6>

                    <div className='dashboard_new__actions_buttons'>
                        {/* action button */}
                        <div className='dashboard_new__actions' ref={actionsRef}>
                            <div className='dashboard_new__actions_button' onClick={() => setShowActions(!showActions)}>
                                <div>Actions</div>
                                <ArrowDropDown />
                            </div>
                            {
                                showActions && <IQList items={action} />
                            }
                        </div>
                    </div>

                </div>


               

                {
                    plans && favoirtPlans && selectedPlan && !plansLoading ?
                        <div className='dashboard_new__plans_area'>
                            <h6 className='dashboard_new__title'>Plans Found: {plansPagination?.total_items}</h6>
                            <div className='dashboard_new__plans'>
                                {
                                    plans.map((plan, index) => (
                                        <PlanNew key={index} plan={plan} fav={isFavorite(plan.id)} selectedPlan={isSelectedPlan(plan.id)} selectPlan={setSelectedPlan} handleClick={() => handlePlanChange(plan.id)} />
                                    ))
                                }
                                {
                                    plans?.length !== plansPagination?.total_items && 
                                    <div className='btn_load_more_container'>
                                        <div className='btn_load_more' onClick={!loadMoreLoading ? (() => handleLoadMore()): undefined}>{!loadMoreLoading ? "+" : <IQSpinner/>}</div>
                                    </div>
                                }
                            </div>
                        </div>

                        : <div style={{ display: "flex", gap: "1rem", justifyContent: "space-between" }}>
                            <LoadingSkeleton />
                            <LoadingSkeleton />
                            <LoadingSkeleton />
                            <LoadingSkeleton />
                        </div>
                }

                <div className='dashboard_new__parcel_area'>
                    <div className='dashboard_new__parcel_map'>
                        <h6 style={{ marginBottom: "1rem" }}>{!noParcelFound ? "Parcel Map" : "Parcel Details"}</h6>
                        {!noParcelFound && (mapData && !parcelLoading ? <MapWithPolygon polygon={mapData} /> : <LoadingSkeleton />)}
                        {noParcelFound && <div style={{ width: "100%" }}><div>No Parcel Data</div></div>}
                    </div>

                    {
                        !noParcelFound && <div className='dashboard_new__parcel_details'>
                            {parcelData && zoningData && !parcelLoading ? <ParcelDetails parcelInfo={parcelData} zoningInfo={zoningData} /> : <LoadingSkeleton />}
                        </div>
                    }

                </div>
                {/* <div className='dashboard_new__comparable_sales'>
                    <h6>Comparable Sales</h6>
                    {
                        !noComparableSalesFound && (comparableSalesData && !comparableSalesLoading ? <SalesTable rows={comparableSalesData} /> : <LoadingSkeleton width={"100%"} />)

                    }
                    {noComparableSalesFound && <div style={{ width: "100%" }}><div>No Comparable Sales Data</div></div>}
                </div> */}
            </div>

            {
                filterBar &&
                <div className='filter_bar'>
                    <NewFilter
                        handleClose={() => setFilterBar(false)}
                        filterData={filter}
                        setFilterData={setFilter}
                        cost={acquisitionCost}
                        setCost={setAcquisitionCost}
                        handleFilterButton={handleFilterButtonClick}
                    />
                </div>
            }

            {
                filterBar && <div onClick={() => setFilterBar(false)} className='filter_overflow'>
                    &nbsp;
                </div>
            }

            {/* {
                loading && <IQLoading message={"Setting up dashboard"} />
            } */}
            {
                info && <IQInformationPop message={infoMessage} setVisibility={setInfo} />
            }

            {
                mobileSideBar && <OtherNav handleMobileSidebar={handleMobileSidebar} />
            }

            <Snackbar
                open={toastShow}
                autoHideDuration={6000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default DashboardGuthrie