import React from 'react'
import ApSubject from './ApSubject'
// import ApContract from './ApContract'
import ApNeighborhood from './ApNeighborhood'
import ApSite from './ApSite'
import ApImprovements from './ApImprovements'
import { apFormHeadTitle } from './ApConfiguration'

function FinalReportPage1() {
    return (
        <div className='final_report'style={{padding:"2rem"}}>
            <div className='final_report_title'>{apFormHeadTitle}</div>
            <ApSubject />
            {/* <ApContract /> */}
            <ApNeighborhood />
            <ApSite />
            <ApImprovements />
        </div>
    )
}

export default FinalReportPage1