import React, { useState } from 'react'
import "./ParcelDetails.css"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';

function ParcelDetails({ parcelInfo, zoningInfo }) {
    function roundToTwo(num) {
        if (num && num !== "-") {
            return Math.round(num * 100) / 100;
        }
        return "-";
    }


    const zoningStyle = {
        height: "3.4rem",
        borderStyle: "solid",
        borderWidth: "0 0 0 0.217rem",
        borderRadius: "0.3rem",
        borderColor: "var(--primary-clr)",
        paddingLeft: "1rem"
    }

    const zoningAccordionStyle = {
        borderRadius: "0.3rem",
        paddingLeft: "1rem"
    }

    const ParcelInformation = () => {
        return parcelInfo.parcel_area !== "-" ? (
            <div className='parcel_information'>
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/gree_lot_size_icon.svg" alt='lat' style={{ width: "2.25rem", height: "2.25rem" }} />
                        <h6>Lot Size</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.parcel_area)} sqft`}</div>
                </div>
                {/* <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/property_icon.svg" alt='lat' />
                        <h6>Setback Area</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.maximum_footprint_area)} sqft`}</div>
                </div> */}
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/lot_size_icon.svg" alt='lat' />
                        <h6>Min Setback Area</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.minimum_setback_area)} sqft`}</div>
                </div>
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/lot_size_icon.svg" alt='lat' />
                        <h6>Min Open Space Area</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.mnimum_open_space_area)} sqft`}</div>
                </div>
                {/* <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/lot_size_icon.svg" alt='lat' />
                        <h6>Max Coverage Area</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parseFloat(parcelInfo.parcel_area) - parseFloat(parcelInfo.mnimum_open_space_area))} sqft`}</div>
                </div> */}
                <div className='parcel_information__item' style={{ backgroundColor: "#E7EFEC" }}>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/gold_lot_size_icon.svg" style={{ width: "2.25rem", height: "2.25rem" }} alt='lat' />
                        <h6>Max Buildable Area</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.maximum_buildable_area)} sqft`}</div>
                </div>

                <div className='parcel_information__item' style={{ backgroundColor: "#E7EFEC" }}>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/length_icon.svg" alt='lat' />
                        <h6>Max Foundation Depth</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.max_foundation_depth)} ft`}</div>
                </div>
                <div className='parcel_information__item' style={{ backgroundColor: "#E7EFEC" }}>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/width_icon.svg" alt='lat' />
                        <h6>Max Foundation Width</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.max_foundation_width)} ft`}</div>
                </div>

                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/length_icon.svg" alt='lat' />
                        <h6>Lot Depth</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.lot_depth)} ft`}</div>
                </div>
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/width_icon.svg" alt='lat' />
                        <h6>Lot Width</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.lot_width)} ft`}</div>
                </div>

                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/property_icon.svg" alt='lat' />
                        <h6>Parcel ID</h6>
                    </div>
                    <div className='parcel_information__data'>{parcelInfo.parcel_id}</div>
                </div>
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/property_icon.svg" alt='lat' />
                        <h6>Parcel Area</h6>
                    </div>
                    <div className='parcel_information__data'>{`${roundToTwo(parcelInfo.parcel_area)} sqft`}</div>
                </div>
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/property_icon.svg" alt='lat' />
                        <h6>Owner Name</h6>
                    </div>
                    <div className='parcel_information__data' style={{ textAlign: "right" }}>{parcelInfo.owner_name}</div>
                </div>
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/property_icon.svg" alt='lat' />
                        <h6 style={{ textWrap: 'nowrap' }}>Owner Address</h6>
                    </div>
                    <div className='parcel_information__data' style={{ textAlign: "right" }}>{parcelInfo.owner_address}</div>
                </div>

                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/long_lat.svg" alt='lat' />
                        <h6>Longitude</h6>
                    </div>
                    <div className='parcel_information__data'>{`${parcelInfo.longitude}`}</div>
                </div>
                <div className='parcel_information__item'>
                    <div className='parcel_information__title_area'>
                        <img src="/images/dashboard_new/long_lat.svg" alt='lat' />
                        <h6>Latitude</h6>
                    </div>
                    <div className='parcel_information__data'>{`${parcelInfo.latitude}`}</div>
                </div>


            </div>
        ) : <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div className='parcel_information__item'>
                <div className='parcel_information__title_area'>
                    <img src="/images/dashboard_new/long_lat.svg" alt='lat' />
                    <h6>Longitude</h6>
                </div>
                <div className='parcel_information__data'>{`${parcelInfo.longitude}`}</div>
            </div>
            <div className='parcel_information__item'>
                <div className='parcel_information__title_area'>
                    <img src="/images/dashboard_new/long_lat.svg" alt='lat' />
                    <h6>Latitude</h6>
                </div>
                <div className='parcel_information__data'>{`${parcelInfo.latitude}`}</div>
            </div>
            <div style={{ flexGrow: "1", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>No Parcel Info</div>
        </div>
    }

    // const renderBuildingTypes = (buildingTypes) => {
    //     return Object.keys(buildingTypes).map((type) => (
    //         <Accordion key={type} >
    //             <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
    //                 <Typography className='accordian_title' style={{ textTransform: "capitalize", fontWeight: "500" }}>{type.replace(/_/g, ' ')}</Typography>
    //             </AccordionSummary>
    //             <AccordionDetails>
    //                 <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Permit Type:</span> <span>{buildingTypes[type].permit_type}</span></Typography>
    //                 {buildingTypes[type].minimum_lot_area && (
    //                     <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Minimum Lot Area:</span> <span>{buildingTypes[type].minimum_lot_area}</span></Typography>
    //                 )}
    //                 {buildingTypes[type].minimum_lot_area_per_unit && (
    //                     <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Minimum Lot Area per Unit:</span> <span>{buildingTypes[type].minimum_lot_area_per_unit}</span></Typography>
    //                 )}
    //                 {buildingTypes[type].minimum_lot_width && (
    //                     <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Minimum Lot Width:</span> <span>{buildingTypes[type].minimum_lot_width}</span></Typography>
    //                 )}
    //             </AccordionDetails>
    //         </Accordion>
    //     ));
    // };

    // const renderResidentialData = (residentialData) => {
    //     return Object.keys(residentialData).map((type) => (
    //         <Accordion key={type} style={zoningAccordionStyle}>
    //             <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
    //                 <Typography style={{ textTransform: "capitalize", fontWeight: "500" }}>{type.replace(/_/g, ' ')}</Typography>
    //             </AccordionSummary>
    //             <AccordionDetails>
    //                 <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Permit Type:</span> <span>{residentialData[type].permit_type}</span></Typography>
    //                 {residentialData[type].minimum_lot_area && (
    //                     <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Minimum Lot Area:</span> <span>{residentialData[type].minimum_lot_area}</span></Typography>
    //                 )}
    //                 {residentialData[type].minimum_lot_area_per_unit && (
    //                     <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Minimum Lot Area per Unit:</span> <span>{residentialData[type].minimum_lot_area_per_unit}</span></Typography>
    //                 )}
    //                 {residentialData[type].minimum_lot_width && (
    //                     <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Minimum Lot Width:</span> <span>{residentialData[type].minimum_lot_width}</span></Typography>
    //                 )}
    //                 {residentialData[type].minimum_open_space_per_unit && (
    //                     <Typography sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}><span style={{ fontWeight: "500" }}>Minimum Open Space per Unit:</span> <span>{residentialData[type].minimum_open_space_per_unit}</span></Typography>
    //                 )}
    //                 {residentialData[type].building_type && renderBuildingTypes(residentialData[type].building_type)}
    //             </AccordionDetails>
    //         </Accordion>
    //     ));
    // };

    // const zoningInformation = () => {
    //     // console.log(zoningInfo);
    //     return zoningInfo.reference ? (
    //         <div className='parcel_information'>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Reference:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>
    //                     <a
    //                         href={zoningInfo.reference}
    //                         target="_blank"
    //                         rel="noreferrer"
    //                     >
    //                         www.municode.com
    //                     </a>
    //                 </div>
    //             </div>

    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Zoning Classification:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>Residential</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Zoning Code:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.zoning_code}</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Front Setback:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.front_setback}</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Max Building Height:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.max_building_height}</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Min Open Space Area:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.minimum_open_space_area}</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Min Other Street Setback:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.minimum_other_street_setback}</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Min Street Frontage:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.minimum_street_frontage}</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Rear Setback:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.rear_setback}</div>
    //             </div>
    //             <div className='parcel_information__item' style={zoningStyle}>
    //                 <div className='parcel_information__title_area'>
    //                     <h6>Side Setback:</h6>
    //                 </div>
    //                 <div className='parcel_information__data'>{zoningInfo.side_setback}</div>
    //             </div>


    //             {zoningInfo.residential_data && renderResidentialData(zoningInfo.residential_data)}
    //         </div>
    //     ): <div style={{flexGrow:"1", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>No Zoning Info</div>;
    // }


    // Helper function to capitalize and replace underscores with spaces
    const formatKey = (key) => key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

    // Recursive function to render data
    const renderData = (data) => {
        return Object.keys(data).map((key) => {
            const value = data[key];
            if (typeof value === 'object' && value !== null) {
                // Render nested data within an accordion
                return (
                    <Accordion key={key} style={zoningAccordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                            <Typography className='accordion_title' style={{ textTransform: "capitalize", fontWeight: "500" }}>{formatKey(key)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderData(value)}
                        </AccordionDetails>
                    </Accordion>
                );
            }
            // Render primitive values directly
            return (
                // <Typography key={key} sx={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}>
                //     <span style={{ fontWeight: "500" }}>{formatKey(key)}:</span> <span>{value}</span>
                // </Typography>
                key !== "reference" && <div key={key} className='parcel_information__item' style={zoningStyle}>
                    <div className='parcel_information__title_area'>
                        <h6>{formatKey(key)}:</h6>
                    </div>
                    <div className='parcel_information__data'>{value}</div>
                </div>
            );
        });
    };

    const ZoningInformation = () => {
        return zoningInfo.reference ? (
            <div className='parcel_information'>
                <div className='parcel_information__item' style={zoningStyle}>
                    <div className='parcel_information__title_area'>
                        <h6>Reference:</h6>
                    </div>
                    <div className='parcel_information__data'>
                        <a href={zoningInfo.reference} target="_blank" rel="noreferrer">www.municode.com</a>
                    </div>
                </div>
                {renderData(zoningInfo)}
            </div>
        ) : (
            <div style={{ flexGrow: "1", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                No Zoning Info
            </div>
        );
    };



    return (
        <div className="parcel_and_zoning" style={{display:"flex", gap:"2rem"}}>
            <div className='parcel_details dashboard_new__parcel_details' style={{height:"100%", padding:"1.5rem"}} >
                <div className='parcel_details__titles' style={{ padding: "0.5rem" }}>
                    <h6 style={{color:"var(--text-clr)"}}>Parcel Information</h6>
                </div>
                <ParcelInformation />
            </div>
            <div className='parcel_details dashboard_new__parcel_details' style={{height:"100%", padding:"1.5rem"}}>
                <div className='parcel_details__titles' style={{ padding: "0.5rem" }}>
                    <h6 style={{color:"var(--text-clr)"}}>Zoning Information</h6>
                </div>
                <ZoningInformation />
            </div>
        </div>
    )
}

export default ParcelDetails