import React from 'react'

function ApSubjectPhoto() {
    return (
        <div style={{ padding: "1.5rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
            <div className='final_report_table_title'>Subject Photo Page</div>
            <table border={1}>
                <tbody>
                    <tr style={{ width: "100%" }}>
                        <td colSpan={4}>Borrower: Jhon</td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td colSpan={4}>Property Address: 123, asdada, tulsa</td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td>City: Tulsa</td>
                        <td>County: Tulsa</td>
                        <td>State: Oklohoma</td>
                        <td>Zip Code: 1111</td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td colSpan={4}>Lender/Client: The Bank</td>
                    </tr>
                </tbody>
            </table>
            <div style={{ display: "flex", gap: "2rem", width: "100%" }}>
                <div style={{ width: "50%" }}>
                    <img style={{ width: "100%" }} src='/images/apparaisal/ap_subject_photo1.jpeg' alt='subject' />
                </div>
                <div style={{ width: "50%" }}>
                    <div className='final_report_table_title' style={{ textAlign: "left" }}>Subject Front</div>
                    <table style={{ width: "50%" }}>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Address</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Sales Price</td>
                            </tr>
                            <tr>
                                <td>Gross Living Area</td>
                                <td>1,192</td>
                            </tr>
                            <tr>
                                <td>Total Rooms</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>Total Bedrooms</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>Total Bathrooms</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>Location</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>View</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>Site</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>Quality</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>Age</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default ApSubjectPhoto