import React from 'react'
import ApSales from './ApSales'
import ApReconciliation from './ApReconciliation'
import { apFormHeadTitle } from './ApConfiguration'

function FinalReportPage2() {
    return (
        <div className='final_report' style={{padding:"2rem"}}>
            <div className='final_report_title'>{apFormHeadTitle}</div>
            <ApSales/>
            <ApReconciliation/>
        </div>
    )
}

export default FinalReportPage2