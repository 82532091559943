import React from 'react'

function ApSubject() {
    const subject = {
        address: '1234 Elm St',
        city: 'Tulsa',
        state: 'OK',
        zip: '74104',
        borrower: 'John Doe',
        ownerOfPublicRecord: 'Jane Smith',
        county: 'Tulsa County',
        legalDescription: 'Lot 12, Block 5, Maplewood Addition',
        assessorParcel: '176-15-301-024',
        taxYear: '2024',
        realEstateTaxes: '1250.50',
        neighborhoodName: 'Maplewood',
        mapReference: 'Block 5, Lot 12',
        censusTract: '2603',
        occupant: "Owner",
        specialAssessments: '150.75',
        pudHoaPerYear: '350.00',
        pudHoaPerMonth: '29.17',
        propertyRightsAppraised: 'Fee Simple',
        feeSimple: 'Yes',
        leasehold: 'No',
        otherDescription: 'N/A',
        assignmentType: 'Purchase Transaction',
        purchaseTransaction: 'Yes',
        refinanceTransaction: 'No',
        otherTransactionDescription: 'N/A',
        lenderClientAddress: '123 Lender St, Tulsa, OK 74104'
    };
    return (
        <table border="2">
            <tbody>
                <tr>
                    <td colSpan={5} style={{ border: "2px solid black", padding: "0 8px" }}>The purpose of this summary appraisal report is to provide the lender/client with an accurate, and adequately supported, opinion of the market value of the subject property</td>
                </tr>
                <tr>
                    <td className='vertical-text' rowSpan={12}>S u b j e c t</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>{`Property Address: ${subject.address}`}</td>
                    <td>{`City: ${subject.city}`}</td>
                    <td>{`State: ${subject.state}`}</td>
                    <td>{`Zip Code: ${subject.zip}`}</td>
                </tr>

                <tr className='app_form_sb'>
                    <td>{`Borrower: ${subject.borrower}`}</td>
                    <td>{`Owner of Public Record: ${subject.ownerOfPublicRecord}`}</td>
                    <td>{`County: ${subject.county}`}</td>
                </tr>

                <tr className='app_form_sb'>
                    <td>{`Legal Description: ${subject.legalDescription}`}</td>
                </tr>


                <tr className='app_form_sb'>
                    <td>{`Assessor’s Parcel #: ${subject.assessorParcel}`}</td>
                    <td>{`Tax Year: ${subject.taxYear}`}</td>
                    <td>{`R.E. Taxes $: ${subject.realEstateTaxes}`}</td>
                </tr>

                <tr className='app_form_sb'>
                    <td>{`Neighborhood Name: ${subject.neighborhoodName}`}</td>
                    <td>{`Map Reference: ${subject.mapReference}`}</td>
                    <td>{`Census Tract: ${subject.censusTract}`}</td>
                </tr>

                <tr className='app_form_sb'>
                    <td>{`Occupant: ${subject.occupant}`}</td>
                    <td>{`Special Assessments $: ${subject.specialAssessments}`}</td>
                    <td>{`☐ PUD`}</td>
                    <td>{`HOA $: ${subject.pudHoaPerMonth}`}</td>
                    <td>{`☐ per year  ☐ per month`}</td>
                </tr>

                <tr className='app_form_sb'>
                    <td>{`Property Rights Appraised: Fee Simple`}</td>
                </tr>

                <tr className='app_form_sb'>
                    <td>{`Assignment Type: Purchase Transaction`}</td>
                </tr>

                <tr className='app_form_sb'>
                    <td>{`Lender/Client : ${subject.borrower}`}</td>
                    <td>{`Address: ${subject.lenderClientAddress}`}</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>{`Is the subject property currently offered for sale or has it been offered for sale in the twelve months prior to the effective date of this appraisal? ☐ Yes ☒ No`}</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>{`Report data source(s) used, offering price(s), and date(s).`}</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>{`The subject has not been listed on the MLS in the past 12 months.`}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default ApSubject