import React, { useEffect, useRef, useState } from 'react'
import './MetricCard.css'
import { Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { LoadingSkeletonText } from '../../../components/skeleton_loading/SkeletonLoading'
import MDropDown from './MDropDown'

function MetricCard({ icon, icon_back, icon_alt, title, tt_text, amount, increase_amount, loading, isNegative }) {

    const [backColor, setBackColor] = useState({
        backgroundColor: "white",
        color: "#2E2C34"
    });
    useEffect(() => {
        if (isNegative) {
            setBackColor({
                backgroundColor: "rgb(255, 46, 46)",
                color: "#fff"
            })
        }
        else if (title === "Equity") {
            setBackColor({
                backgroundColor: "var(--primary-clr)",
                color: "#fff"
            })
        }
    }, [amount])

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    // Close the tooltip on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div className='metric_card' style={backColor} onClick={() => setShowTooltip(!showTooltip)} ref={tooltipRef}>
            <div className='metric_card__text_area'>
                <div className='metric_card__icon' style={{ backgroundColor: icon_back }}>
                    <img src={`/images/dashboard_new/${icon}.svg`} alt={icon_alt} style={{ borderRadius: "50%" }} />
                </div>
                <h5 style={{ color: title === "Equity" ? "#fff" : "#626262" }}>{title}<br/><span style={{fontSize:"0.6rem"}}>(Aggregate)</span></h5>
                <Tooltip title={tt_text} arrow open={showTooltip}>
                    <InfoOutlined sx={{ width: "1rem", height: "1rem" }} />
                </Tooltip>
            </div>
            <div className='metric_card__amount'>
                {loading ? <LoadingSkeletonText width={"8ch"} /> : amount}
            </div>
            
        </div>
    )
}

export default MetricCard