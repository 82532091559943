import React from 'react'

function ApIncome() {
    return (
        <table border="2" style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={4}>I N C O M E</td>
                    <td colSpan={2} style={{ width: "100%", fontWeight: "bold", textAlign: "center", backgroundColor: "lightgray" }}>INCOME APPROACH TO VALUE (not required by Fannie Mae)</td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Estimated Monthly Market Rent: $1,500 X Gross Rent Multiplier: 120 = $180,000 Indicated Value by Income Approach
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Summary of Income Approach (including support for market rent and GRM)
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        &nbsp;
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default ApIncome