import React from 'react'
import "./FinalReport.css"
import FinalReportPage3 from './FinalReportPage3'
import { useState } from 'react'
import FinalReportPage1 from './FinalReportPage1';
import FinalReportPage2 from './FinalReportPage2';
import ApTableContent from './ApTableContent';
import ApLocationMap from './ApLocationMap';
import ApSubjectPhoto from './ApSubjectPhoto';
import ApComparable1 from './ApComparable1';
import UserNav from '../../../../../components/user_nav/UserNav';
import OtherNav from '../../../../../components/other_nav/OtherNav';

function FinalReport() {



    const total_page = 7;
    const [page, setPage] = useState(1);
    
    const [isActive, setIsActive] = useState(false);

    const [mobileSideBar, setMobileSidebar] = useState(false);

    const handleMobileSidebar = () => {
        setMobileSidebar(!mobileSideBar);
        setIsActive(!isActive);
    };

    return (
        <div className='final_report'>
            <div className='copilot_new__navigation'>
                <UserNav tab={"app"} handleBurgerClick={handleMobileSidebar} />
            </div>
            <div>
                &nbsp;
            </div>
            <div style={{ fontSize: "small" }}>

                <ApTableContent />
                <div className='final_report_page_num'>1 of {total_page}</div>
                <div className='report_page_break'></div>
                <FinalReportPage1 />
                <div className='final_report_page_num'>2 of {total_page}</div>
                <div className='report_page_break'></div>
                <FinalReportPage2 />
                <div className='final_report_page_num'>3 of {total_page}</div>
                <div className='report_page_break'></div>
                <FinalReportPage3 />
                <div className='final_report_page_num'>4 of {total_page}</div>
                <div className='report_page_break'></div>
                <ApLocationMap />
                <div className='final_report_page_num'>5 of {total_page}</div>
                <div className='report_page_break'></div>
                <ApSubjectPhoto />
                <div className='final_report_page_num'>6 of {total_page}</div>
                <div className='report_page_break'></div>
                <ApComparable1 />
                <div className='final_report_page_num'>7 of {total_page}</div>
                <div className='report_page_break'></div>

            </div>
            <div className='final_report_buttons'>
                <div>&nbsp;</div>
                <div className='final_report_next_prev'>
                &nbsp;
                </div>
                <div className='final_report_share_download'>
                    <div className='final_report_button'>Share</div>
                    <div className='final_report_button'>Download</div>
                </div>
            </div>

            <OtherNav isActive={mobileSideBar} setIsActive={setMobileSidebar} />
        </div>
    )
}

export default FinalReport