export const calculateMapZoom = (coordinates, mapWidth, mapHeight) => {
    if (coordinates.length === 0) return 10; // Default zoom level

    // Convert degrees to radians
    const degToRad = (deg) => (deg * Math.PI) / 180;

    // Find bounds
    let minLat = 90,
        maxLat = -90,
        minLng = 180,
        maxLng = -180;

    coordinates.forEach(({ lat, lng }) => {
        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
    });

    // Latitude/Longitude bounds
    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;

    // Approximate Earth's radius in meters
    const EARTH_RADIUS = 6378137;

    // Calculate distances
    const latDistance = degToRad(latDiff) * EARTH_RADIUS;
    const lngDistance = degToRad(lngDiff) * EARTH_RADIUS * Math.cos(degToRad((minLat + maxLat) / 2));

    // Determine the scale factor for zoom
    const maxDistance = Math.max(latDistance, lngDistance);
    const distancePerPixel = maxDistance / Math.min(mapWidth, mapHeight);
    const zoomLevel = Math.log2((EARTH_RADIUS * 2 * Math.PI) / (256 * distancePerPixel)) - 1;

    return Math.min(Math.max(Math.floor(zoomLevel), 0), 21); // Clamp between 0 and 21
};

export const calculateMapCenter = (coordinates) => {
    if (!coordinates.length) return { lat: 0, lng: 0 };

    const latSum = coordinates.reduce((sum, loc) => sum + loc.lat, 0);
    const lngSum = coordinates.reduce((sum, loc) => sum + loc.lng, 0);

    const latAvg = latSum / coordinates.length;
    const lngAvg = lngSum / coordinates.length;

    return { lat: latAvg, lng: lngAvg };
};


export const transformCoordunates = (coordinates) => coordinates.map(([lng, lat]) => ({ lat, lng }));