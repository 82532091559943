import { GoogleMap, OverlayView, OverlayViewF, PolygonF, useJsApiLoader } from '@react-google-maps/api'
import React, { Fragment, useEffect, useState } from 'react'
import './MapWithPolygon.css'
import './SubdivisionMap.css'
import { LoadingSkeletonText } from '../../../components/skeleton_loading/SkeletonLoading'
import { useNavigate } from 'react-router-dom'
import { calculateMapCenter, calculateMapZoom, transformCoordunates } from '../../../components/util_component/map_function/MapFunctions'

const libraries = ['places']


function SubdivisionMap({ polygon, setParcel }) {

  const navigate = useNavigate();




  const [mapCenter, setMapCenter] = useState();
  const [mapZoom, setMapZoom] = useState();
  const [selectedParcel, setSelectedParcel] = useState();


  const handleSubdivisionClick = (subdivision) => {
    setSelectedParcel(subdivision);
    setParcel(subdivision);
    if (subdivision.id === selectedParcel?.id) {
      return
    }

  };




  useEffect(() => {
    const tranformedCoordinates = transformCoordunates(polygon.parcel_polygon);
    setMapCenter(calculateMapCenter(tranformedCoordinates))
    setMapZoom(calculateMapZoom(tranformedCoordinates, 800, 800))
  }, [polygon.parcel_polygon])


  const [hoveredPolygon, setHoveredPolygon] = useState(null);


  // set map size
  const mapContainerStyle = {
    height: '60vw',
    width: '100%'
  }

  // Needed to first call to render map
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: libraries,
  });

  if (!isLoaded) {
    return <LoadingSkeletonText width={"100%"} />
  }

  if (loadError) {
    return <div>{loadError.message}</div>
  }


  return (
    <div style={{ position: "relative" }}>
      <GoogleMap
        center={mapCenter}
        zoom={mapZoom}
        mapContainerStyle={mapContainerStyle}
        options={
          {
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            // styles: mapStyle
          }
        }
      >

        {
          polygon.subdivisions.map((subdivision) => (
            <Fragment key={subdivision.id}>
              <PolygonF
                key={`parcel_${subdivision.id}`}
                paths={transformCoordunates(subdivision.parcel_polygon)}
                options={{
                  fillColor: selectedParcel?.id === subdivision.id ? '#093623' : '#126342',
                  fillOpacity: 0.8,
                  strokeColor: '#0c462f',
                  strokeOpacity: 1,
                  strokeWeight: 2,

                }}
                onClick={() => handleSubdivisionClick(subdivision)}
              />
              <PolygonF
                key={`setback_${subdivision.id}`}
                paths={transformCoordunates(subdivision.setback_polygon)}
                options={{
                  fillColor: selectedParcel?.id === subdivision.id ? '#8A6E46' : "#DFBB7F",
                  fillOpacity: 0.8,
                  strokeColor: 'black',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
                onClick={() => handleSubdivisionClick(subdivision)}
                onMouseOver={() => setHoveredPolygon(subdivision.id)}
                onMouseOut={() => setHoveredPolygon(null)}
              />

              {hoveredPolygon === subdivision.id && (
                <OverlayView
                  position={calculateMapCenter(transformCoordunates(subdivision.setback_polygon))}
                  mapPaneName={OverlayView.FLOAT_PANE}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "5px",
                      borderRadius: "3px",
                      color: "#333",
                      fontWeight: "bold",
                      boxShadow: "0px 2px 6px rgba(0,0,0,0.3)",
                      width: "100px",
                      textAlign: "center",
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center",
                      height:"20px",
                      transform:"translateX(-75%)"
                    }}
                  >
                    {`Equity: $${subdivision?.kpis?.equity}`}
                  </div>
                </OverlayView>
              )}


            </Fragment>
          ))
        }


      </GoogleMap>
      <div
        className={`button_on_parcel ${selectedParcel ? "button_on_parcel_active" : "button_on_parcel_disabled"}`}
        onClick={selectedParcel ? () => navigate(`/dashboard?parcel=1`) : null}
      >
        Populate SmartBuild
      </div>
    </div>
  )
}

export default SubdivisionMap