import React, { useState } from 'react'
import './ChatbotInputBar.css'
import { useRef } from 'react';
import { useEffect } from 'react';
import IQSpinner from '../../util_component/iq_spinner/IQSpinner';
import GoogleAddressInput from '../../google_map/GoogleAddressInput';
import ParcelInputBar from './ParcelInputBar';
import { ChangeCircleOutlined } from '@mui/icons-material';
import { TextField, Tooltip } from '@mui/material';

function ChatbotInputBar({ onSendMessage, isLoading, message, setMessage, prePrompt, setPreprompt }) {
  // const [message, setMessage] = useState('');
  const textareaRef = useRef(null);

  const [address, setAddress] = useState("");

  const [inputSwap, setInputSwap] = useState(false);
  const [parcelDetails, setParcelDetails] = useState({
    parcel_id: "",
    state: "",
    county: ""
  })

  const handleSend = () => {
    if (message.trim()) {
      if (prePrompt) {
        if (inputSwap && parcelDetails.parcel_id !== "" && parcelDetails.state !== "" && parcelDetails.county !== "") {
          onSendMessage(prePrompt.content + " for " + parcelDetails.parcel_id + " in " + parcelDetails.state + ", " + parcelDetails.county + " county");
          setParcelDetails({
            parcel_id: "",
            state: "",
            county: ""
          })
        }
        else if (!inputSwap && address !== "") {
          onSendMessage(prePrompt.content + " for " + address);
          setAddress("")
        }
      }
      else {
        onSendMessage(message);
      }
      setMessage('');
      setPreprompt(undefined);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleCloseClick = () => {
    setPreprompt(undefined);
    setMessage("");
  }

  useEffect(() => {
    setMessage(prePrompt && prePrompt.content)
  }, [prePrompt])

  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset height to auto to shrink the textarea if necessary
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight to fit content
  }, [message]);

  // return (
  //   <div >

  //     {
  //       prePrompt !== "" && <GoogleAddressInput address={address} setAddress={setAddress} handleKeyPress={handleKeyPress}/>
  //     }
  //     <div className="chat-input-container" >
  //       <textarea
  //         ref={textareaRef}
  //         value={prePrompt !== "" ? prePrompt : message}
  //         onChange={(e) => setMessage(e.target.value)}
  //         onKeyPress={handleKeyPress}
  //         placeholder="Ask a question to Copilot..."
  //         rows="1"
  //         disabled={prePrompt !== ""}
  //       />
  //       <button onClick={handleSend} className='send_button'>
  //         {
  //           !isLoading? <img src="/images/copilot_new/send_icon.svg" alt='send'/>: <IQSpinner/>
  //         }
  //       </button>

  //       {prePrompt !== "" && <div className='preprompt_close' onClick={() => setPreprompt("")}>x</div>}
  //     </div>
  //     {/* <div className='chat-input-container-bottom'>
  //       <div className='chat-input-file-attach-area'>
  //         <div className='chat_input_attachment'>
  //           <img src="/images/copilot_new/attachment_icon.svg" alt='attachment' />
  //           <div className='attach_text'>Attach Document</div>
  //         </div>
  //         <div className='or'>or</div>
  //         <h6>Drag & Drop file here</h6>
  //       </div>
  //       <button onClick={handleSend} className='send_button' style={{alignSelf:'flex-end'}}>
  //         {
  //           !isLoading? <img src="/images/copilot_new/send_icon.svg" alt='send'/>: <IQSpinner/>
  //         }
  //       </button>

  //     </div> */}
  //   </div>
  // );

  return (
    <div className='chatbot_input_bar_new'>

      {
        prePrompt &&
        <div>
          <GoogleAddressInput address={address} setAddress={setAddress} handleKeyPress={handleKeyPress} label={prePrompt?.address_box}/></div>
      }
      {/* {
        prePrompt && prePrompt?.id === 3 &&
        <div style={{marginBottom:"4px" }}>
          <TextField
            label="Zip Code"
            variant="outlined"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              style: {
                height: '2.5rem',
                padding: '0', // Adjust padding around text
              },
            }}
            InputLabelProps={{
              style: {
                top: '-5px', // Adjust the initial label position if it's too low
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                transform: 'translate(14px, -5px) scale(0.75)', // Adjusts label position for focused state
              },
              '& .MuiOutlinedInput-root': {
                height: '2.5rem', // Adjusts the input container height
              },
            }}
            
          />
        </div>

      } */}

      <div className="tulsa_chat-input-container">
        <textarea
          ref={textareaRef}
          value={prePrompt? prePrompt.content : message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Ask a question to Copilot..."
          rows="1"
          disabled={prePrompt}
        />

        <div className='tulsa_send_button_area'>
          <button onClick={handleSend} className='tulsa_send_button'>
            {
              !isLoading ? <img src="/images/copilot_new/send_icon.svg" alt='send' /> : <IQSpinner />
            }
          </button>
          {prePrompt && <div className='preprompt_close' onClick={handleCloseClick}>x</div>}
        </div>

        {
          // prePrompt !== "" && <>
          //   <Tooltip title={`Swap it to ${!inputSwap ? "Parcel ID" : "Address"}`}>
          //     <div className='parcel_input_swap' onClick={() => setInputSwap(!inputSwap)}>
          //       <ChangeCircleOutlined className='swap_icon' />
          //     </div>
          //   </Tooltip>
          //   {/* <div className='parcel_input_swap_text'>
          //       {!inputSwap ? "Parcel ID" : "Address"}
          //   </div> */}
          // </>
        }

      </div>
    </div>
  );


};
export default ChatbotInputBar