import React, { useEffect, useRef, useState } from 'react'
import UserNav from '../../components/user_nav/UserNav'
import './DashboardNew.css'
import MetricCard from './component/MetricCard'
import ParcelDetails from './component/ParcelDetails'
import GoogleAddressInput from '../../components/google_map/GoogleAddressInput'
import IQList from '../../components/util_component/iq_list/IQList'
import MDropDown from './component/MDropDown'
import { ArrowDropDown } from '@mui/icons-material'
import MetricCardAcc from './component/MetricCardAcc'
import { useNavigate } from 'react-router-dom'
import { sampleKpi, sampleZoning1, sampleZoning2, sampleZoning3 } from './data/SampleData'
import SubdivisionMap from './component/SubdivisionMap'

function SubdivisionDash() {

    const navigate = useNavigate();

    // convert to currency
    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);
    }

    const [showActions, setShowActions] = useState(false);
    const [parcel, setParcel] = useState(sampleZoning3);

    // actions list
    const action = [
        // {
        //     text: "Download the report",
        //     action: null
        // },
        {
            text: "Share report",
            action: () => {

            }
        },
        {
            text: "Favorites",
            action: () => navigate("/favorite")
        }

    ]

    // handle outside click
    const actionsRef = useRef(null);
    const handleClickOutside = (event) => {
        if (actionsRef.current && !actionsRef.current.contains(event.target)) {
            setShowActions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // end of handle outside click



    // handle build quality
    const [buildQuality, setBuildQuality] = useState("BRONZE");
    const [rezone, setRezone] = useState("RS-3")

    // handle request when click build quality
    const handleBuildQualityClick = (quality) => {

        setBuildQuality(quality);

    }

    const handleZoneChange = (zone) => {
        setRezone(zone);
    }

    useEffect(() => {
        if (rezone === "RS-1") {
            setParcel(sampleZoning1)
        } else if (rezone === "RS-2") {
            setParcel(sampleZoning2)
        } else {
            setParcel(sampleZoning3)
        }
    }, [rezone])

    const constractionTooltop = () => {
        return (
            <div className='construction_tooltop'>
                <p>Our Construction Costs computation provides users with an insightful estimate for building a home based on their selected plan. This estimate is drawn from localized construction data, ensuring accurate, region-specific cost projections. For clarity and precision, costs are separated based on whether the space is heated (like living rooms and bedrooms) or unheated (such as unfinished basements and garages). </p>
                <table border="1px" style={{ fontSize: "0.5rem", width: "100%" }}>
                    <thead>
                        <tr>
                            <th>Space Type &nbsp;</th>
                            <th style={{ textAlign: "center" }}>Area(sqft)&nbsp;</th>
                            <th style={{ textAlign: "center" }}>Cost per sqft($)&nbsp;</th>
                            <th style={{ textAlign: "center" }}>Total cost($)&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: "bold", textAlign: "left" }}>Heated</td>
                            <td>{sampleKpi && sampleKpi.area_heated}</td>
                            <td>{sampleKpi && formatCurrency(sampleKpi.msacc_finished)}</td>
                            <td>{sampleKpi && formatCurrency(sampleKpi.cost_finished)}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold", textAlign: "left" }}>Unheated</td>
                            <td>{sampleKpi && sampleKpi.area_unheated}</td>
                            <td>{sampleKpi && formatCurrency(sampleKpi.msacc_unfinished)}</td>
                            <td>{sampleKpi && formatCurrency(sampleKpi.cost_unfinished)}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold", textAlign: "left" }}>Other Costs</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{sampleKpi && formatCurrency(sampleKpi.cost_extra)}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold", textAlign: "left" }}>Construction Cost</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{sampleKpi && formatCurrency(sampleKpi.construction_cost)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }


    return (
        <div className='dashboard_new' style={{ backgroundColor: "rgba(0,155,0,0.02)" }}>
            <div>
                <UserNav tab={"smm"} />
            </div>
            <div className='dashboard_new__main' style={{ marginTop: "5rem" }}>


                {/* Address and action button */}
                <div className='dashboard_new__address_area'>

                    {/* address */}
                    <h6 className='dashboard_new_address'>8226 E 32nd Pl, Tulsa, OK 74145</h6>

                    <div className='dashboard_new__actions_buttons'>
                        <div className='lan_secondary_button mobile_btn_dash' style={{ height: "2.5rem" }}>  Ask Copilot </div>
                        <div className='lan_secondary_button mobile_btn_dash' style={{ height: "2.5rem" }}>  Prepare Appraisal</div>
                        {/* action button */}
                        <div className='dashboard_new__actions' ref={actionsRef}>
                            <div className='dashboard_new__actions_button mobile_btn_dash' onClick={() => setShowActions(!showActions)}>
                                <div>Actions</div>
                                <ArrowDropDown />
                            </div>
                            {
                                showActions && <IQList items={action} />
                            }
                        </div>
                    </div>

                </div>


                {/* Dashboard top  filter */}
                <div className='dashboard_new__top_filter_area'>

                    <div className='dashboard_new__address_input'>
                        <GoogleAddressInput
                            setAddress={() => { }}
                            address={""}
                            handleKeyPress={() => { }}
                        />
                        <div className='keyword_search_btn'>
                            <img src='/images/dashboard_new/search_icon.svg' alt='filter' />
                        </div>
                    </div>


                    <div className='dashboard_new__top_filter'>

                        <MDropDown
                            label={"Build Quality"}
                            values={["BRONZE", "SILVER", "GOLD"]}
                            texts={["Economy", "Standard", "Premium"]}
                            value={buildQuality}
                            setValue={handleBuildQualityClick}
                        />

                        <MDropDown
                            label={"Re-zoning options"}
                            values={["RS-1", "RS-2", "RS-3"]}
                            texts={["RS-1", "RS-2", "RS-3"]}
                            value={rezone}
                            setValue={handleZoneChange}
                        />

                    </div>

                </div>






                {/* Top cards */}
                <div className='metric_cards'>
                    <MetricCard
                        icon={"vac_icon"}
                        icon_back={"#D1AD71"}
                        icon_alt={"vac"}
                        title={"Equity"}
                        tt_text={"Equity represents the residual interest in a property after deducting liabilities, offering insight into the potential financial gain or advantage of the project"}
                        amount={formatCurrency(sampleKpi.equity)}
                        increased={true}
                        // increase_per={"21.9%"}
                        isNegative={sampleKpi.equity < 0}
                        loading={false}
                    />


                    <MetricCard
                        icon={"equity_icon"}
                        icon_back={"#1263421A"}
                        icon_alt={"vac"}
                        title={"Value after Completion"}
                        tt_text={"Our algorithm generates a predictive range for the potential value after completion, offering users a well-informed projection to aid in decision-making processes and future planning"}
                        amount={formatCurrency(sampleKpi.vac)}
                        increased={true}
                        loading={false}
                        // increase_per={"21.9%"}
                        // increase_amount={"+$64K today"}
                        color={"gray"}
                    />

                    <MetricCard
                        icon={"tpc_icon"}
                        icon_back={"#1263421A"}
                        icon_alt={"vac"}
                        title={"Total Project Cost"}
                        tt_text={"The Total Project Costs is a pivotal metric to determine the cumulative expenses you can expect for the entirety of the construction and land acquisition phase"}
                        amount={formatCurrency(sampleKpi.total_project_cost)}
                        increased={true}
                        // increase_per={"21.9%"}
                        color={"gray"}
                        loading={false}
                    />

                    <MetricCard
                        icon={"cc_icon"}
                        icon_back={"#1263421A"}
                        icon_alt={"vac"}
                        title={"Construction Cost"}
                        tt_text={constractionTooltop(sampleKpi)}
                        amount={formatCurrency(sampleKpi.construction_cost)}
                        increased={true}
                        loading={false}
                        // increase_per={"21.9%"}
                        color={"gray"}
                    />

                    <MetricCardAcc
                        icon={"tpc_icon"}
                        icon_back={"#1263421A"}
                        icon_alt={"vac"}
                        title={"Equity"}
                        tt_text={"Equity represents the residual interest in a property after deducting liabilities, offering insight into the potential financial gain or advantage of the project"}
                        amount={sampleKpi.acquisition_cost}
                        setAmount={() => { }}
                        adcAmount={sampleKpi.other_cost}
                        setAdcAmount={() => { }}
                        // fetchKpi={handleAcquisitionOkClick}
                        color={"gray"}
                        loading={false}
                    />
                </div>

                <div className='dashboard_new__parcel_area' style={{ flexDirection: "column" }}>
                    <div className='dashboard_new__parcel_map'>
                        <h6 style={{ marginBottom: "1rem" }}>Parcel Map</h6>
                        <SubdivisionMap polygon={sampleZoning3.map} setParcel={setParcel} />

                    </div>
                    <div >
                        <ParcelDetails parcelInfo={parcel.parcel} zoningInfo={parcel.zoning} />
                    </div>

                </div>

            </div>


        </div>
    )
}

export default SubdivisionDash