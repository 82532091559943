import React from 'react'

function ApReconciliation() {
    return (
        <table border="2" style={{ overflowX: "auto" }}>
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={7}>R E C O N C I L I A T I O N</td>
                    <td colSpan="2" style={{ fontWeight: "bold" }}>
                        Indicated Value by: Sales Comparison Approach $500,000
                        Cost Approach (if developed) $450,000
                        Income Approach (if developed) $480,000
                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan="2">
                        This appraisal is made
                        ☒ “as is”,
                        ☐ subject to completion per plans and specifications on the basis of a hypothetical condition that the improvements have been completed,
                        ☐ subject to the following repairs or alterations on the basis of a hypothetical condition that the repairs or alterations have been completed,
                        or
                        ☐ subject to the following required inspection based on the extraordinary assumption that the condition or deficiency does not require alteration or repair:
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{fontWeight:"bold"}}>Based on a complete visual inspection of the interior and exterior areas of the subject property, defined scope of work, statement of assumptions and limiting conditions, and appraiser’s certification, my (our) opinion of the market value, as defined, of the real property that is the subject of this report is $450,000, as of 11/10/2024, which is the date of inspection and the effective date of this appraisal.</td>
                </tr>
            </tbody>
        </table>
    )
}

export default ApReconciliation