import React from 'react'

function ApNeighborhood() {
    return (
        <div>
            <table border="2">
                <tbody>
                    <tr>
                        <td className='vertical-text' rowSpan={3}>N e i g h b o r h o o d</td>
                    </tr>
                    <tr className='app_form_sb'>
                        <td style={{ fontWeight: "bold" }}>{`Note: Race and the racial composition of the neighborhood are not appraisal factors.`}</td>
                    </tr>
                    <tr className='app_form_sb app_form_sub_table_h' style={{backgroundColor:"gray"}}>
                        <td style={{width:"30%"}}>{`Neighborhood Characteristics`}</td>
                        <td style={{width:"40%"}}>{`One-Unit Housing Trends`}</td>
                        <td style={{width:"15%"}}>{`One-Unit Housing`}</td>
                        <td style={{width:"15%"}}>{`Present Land Use %`}</td>
                    </tr>
                    <tr className="app_form_sb app_form_sub_table_item">
                        <td style={{width:"30%", borderWidth:"0 1px 0 0"}}>Location Type: ☒ Urban ☐ Suburban ☐ Rural</td>
                        <td style={{width:"40%", borderWidth:"0 1px 0 0"}}>Property Values: ☒ Increasing ☐ Stable ☐ Declining</td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>&nbsp; &nbsp; PRICE &nbsp; &nbsp; AGE &nbsp; &nbsp; </td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>One-Unit 34%</td>
                    </tr>
                    <tr className="app_form_sb app_form_sub_table_item">
                        <td style={{width:"30%", borderWidth:"0 1px 0 0"}}>Built-Up: ☒ Over 75% ☐ 25–75% ☐ Under 25%</td>
                        <td style={{width:"40%", borderWidth:"0 1px 0 0"}}>Demand/Supply: ☒ Shortage ☐ In Balance ☐ Over Supply</td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>&nbsp; &nbsp; $ (000) &nbsp; &nbsp; (yrs) &nbsp; &nbsp; </td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>2-4 Unit 40%</td>
                    </tr>
                    <tr className="app_form_sb app_form_sub_table_item">
                        <td style={{width:"30%", borderWidth:"0 1px 0 0"}}>Growth: ☒ Rapid ☐ Stable ☐ Slow</td>
                        <td style={{width:"40%", borderWidth:"0 1px 0 0"}}>Marketing Time: ☒ Under 3 mths ☐ 3–6 mths ☐ Over 6 mths</td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>&nbsp; &nbsp; Low &nbsp; &nbsp; </td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>Multi-Family 20%</td>
                    </tr>
                    <tr className="app_form_sb app_form_sub_table_item">
                        <td style={{width:"30%"}}>&nbsp;</td>
                        <td style={{width:"40%", borderWidth:"0 1px 0 0"}}>&nbsp;</td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>&nbsp; &nbsp; High &nbsp; &nbsp; </td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>Commercial 20%</td>
                    </tr>
                    
                    <tr className="app_form_sb app_form_sub_table_item">
                        <td style={{width:"30%"}}>&nbsp;</td>
                        <td style={{width:"40%", borderWidth:"0 1px 0 0"}}>&nbsp;</td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>&nbsp; &nbsp; Pred. &nbsp; &nbsp; </td>
                        <td style={{width:"15%", borderWidth:"0 1px 0 0"}}>Other 20%</td>
                    </tr>
                    <tr className="app_form_sb">
                        <td>Neighborhood Boundaries</td>
                    </tr>
                    <tr className='app_form_sb'>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className="app_form_sb">
                        <td>Neighborhood Description</td>
                    </tr>
                    <tr className='app_form_sb'>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className='app_form_sb'>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className="app_form_sb">
                        <td>Market Conditions (including support for the above conclusions)</td>
                    </tr>
                    <tr className='app_form_sb'>
                        <td>&nbsp;</td>
                    </tr>
                    <tr className='app_form_sb'>
                        <td>&nbsp;</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    )
}

export default ApNeighborhood