import React from 'react'

function ApPud() {
    return (
        <table border="2" style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={15}>P U D &nbsp;&nbsp; I N F O R M A T I O N</td>
                    <td colSpan={2} style={{ width: "100%", fontWeight: "bold", textAlign: "center", backgroundColor: "lightgray" }}>PROJECT INFORMATION FOR PUDs (if applicable)</td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Is the developer/builder in control of the Homeowners’ Association (HOA)? ☐ Yes ☐ No Unit type(s) ☐ Detached ☐ Attached
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Provide the following information for PUDs ONLY if the developer/builder is in control of the HOA and the subject property is an attached dwelling unit.
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Legal name of project
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Total number of phases 4. Total number of units 4. Total number of units sold 4.
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Total number of units rented 5. Total number of units for sale 8. Data source(s)
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Was the project created by the conversion of an existing building(s) into a PUD? ☐ Yes ☐ No 
                        {/* If Yes, date of conversion */}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Does the project contain any multi-dwelling units? ☐ Yes ☐ No
                        {/* Data source(s) */}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Are the units, common elements, and recreation facilities complete? ☐ Yes ☐ No
                        {/* If No, describe the status of completion. */}
                    </td>
                </tr>
                {/* <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        &nbsp;
                    </td>
                </tr> */}
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Are the common elements leased to or by the Homeowners’ Association? ☐ Yes ☐ No
                    </td>
                </tr>
                {/* <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        &nbsp;
                    </td>
                </tr> */}
                {/* <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        Describe common elements and recreational facilities
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ width: "100%" }}>
                        &nbsp;
                    </td>
                </tr> */}
            </tbody>
        </table>
    )
}

export default ApPud