import React, { useState } from 'react'
import './TulsaChatbotInputBar.css'
import { useRef } from 'react';
import { useEffect } from 'react';
import GoogleAddressInput from '../../../../components/google_map/GoogleAddressInput';
import IQSpinner from '../../../../components/util_component/iq_spinner/IQSpinner';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';



const libraries = ["places"];

function TulsaChatbotInputBar({ onSendMessage, isLoading, message, setMessage, prePrompt, setPreprompt }) {
  // const [message, setMessage] = useState('');
  const textareaRef = useRef(null);

  const [address, setAddress] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      if (prePrompt !== "") {
        onSendMessage(prePrompt + " for " + address);
        setAddress("")
      }
      else {
        onSendMessage(message);
      }
      setMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      clickSend();
    }
  };


  const navigate = useNavigate();

  const clickSend = () =>{  
    onPlaceChanged();
    if(address !== ""){
      navigate("/tulsa/dashboard", {state: {address: address}})
    }
  }



  const autocompleteRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: libraries
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (loadError) {
    return <div>{loadError}</div>;
  }

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.formatted_address) {
      setAddress(place.formatted_address);
    }
  };

  return (
    <div className='tulsa_chat_input_new'>


      <div className="tulsa_chat-input-container">
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
          className='w-100 tulsa_chat_autocomplete_container'
        >
          <input type='text'
          className='address_input_tulsa'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter address or Parcel ID"
          />

        </Autocomplete>
        <div className='tulsa_send_button_area'>
          <button onClick={clickSend} className='tulsa_send_button'>
            {
              !isLoading ? <img src="/images/copilot_new/send_icon.svg" alt='send' /> : <IQSpinner />
            }
          </button>
        </div>
      </div>
    </div>
  );
};
export default TulsaChatbotInputBar