import React from 'react'

function ApImprovements() {
    return (
        <table border="2" style={{ overflowX: "auto" }}>
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={12}>I m p r o v e m e n t s</td>
                </tr>
                <tr className='app_form_sb app_form_sub_table_h' style={{ backgroundColor: "gray" }}>
                    <td>{`General Description`}</td>
                    <td>{`Foundation`}</td>
                    <td style={{ display: "flex", justifyContent: "space-between" }}>{`Exterior Description`}<span>{`materials/condition`}</span></td>
                    <td style={{ display: "flex", justifyContent: "space-between" }}>{`Interior`} <span>{`materials/condition`}</span></td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>Units: ☒ One &nbsp; ☐ One with Accessory Unit</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☒ Concrete Slab &nbsp; ☐ Crawl Space</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Foundation Walls: Brick</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Floors: Concrete</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}># of Stories: 2</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☒ Full Basement &nbsp; ☐ Partial Basement</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Exterior Walls: Brick</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Walls: Drywall</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>Type: ☒ Det. &nbsp; ☐ Att. &nbsp; ☐ S-Det./End Unit</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Basement Area: 1200 sq. ft.</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Roof Surface: Asphalt Shingles</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Trim/Finish: Wood</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>☒ Existing ☐ Proposed &nbsp; ☐ Under Const.</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Basement Finish: 80%</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Gutters & Downspouts: Yes</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Bath Floor: Tile</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>Design (Style): Modern</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☒ Outside Entry/Exit &nbsp; ☐ Sump Pump</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Window Type: Double Hung</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Bath Wainscot: Tile</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>Year Built: 1995</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Evidence of ☐ Infestation</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Storm Sash/Insulated: Asphalt Shingles</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Car Storage: ☐ None</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 2px 0" }}>Effective Age (Yrs): 15</td>
                    <td style={{ borderWidth: "0 1px 2px 0" }}>☐ Dampness &nbsp; ☐ Settlement</td>
                    <td style={{ borderWidth: "0 1px 2px 0" }}>Screens: No</td>
                    <td style={{ borderWidth: "0 1px 2px 0" }}>☐ Driveway &nbsp; # of Cars: 2</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>Year Built: 1995</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Evidence of ☐ Infestation</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Storm Sash/Insulated: Asphalt Shingles</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Car Storage: ☐ None</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>Attic: ☐ None</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Heating: ☒ FWA &nbsp; ☐ HWBB &nbsp; ☐ Radiant</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Amenities: ☒ Woodstove(s) # 1</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Driveway Surface: Asphalt</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>☐ Drop Stair &nbsp; ☐ Stairs</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☐ Other &nbsp;&nbsp; Fuel</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☐ Fireplace(s): ☒ # 2 &nbsp; ☐ Fence</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☐ Garage &nbsp; # of Cars: 1</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 0 0" }}>☐ Floor &nbsp; ☐ Scuttle</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>Cooling ☒ Central Air Conditioning</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☒ Patio/Deck &nbsp; ☐ Porch</td>
                    <td style={{ borderWidth: "0 1px 0 0" }}>☐ Carport &nbsp; # of Cars: 2</td>
                </tr>
                <tr className="app_form_sb app_form_sub_table_item">
                    <td style={{ borderWidth: "0 1px 2px 0" }}>☐ Finished &nbsp; ☐ Heated</td>
                    <td style={{ borderWidth: "0 1px 2px 0" }}>☐ Individual &nbsp; ☐ Other</td>
                    <td style={{ borderWidth: "0 1px 2px 0" }}>☐ Pool &nbsp; ☐ Other</td>
                    <td style={{ borderWidth: "0 1px 2px 0" }}>☐ Att. &nbsp; ☐ Det. &nbsp; ☐ Built-in</td>
                </tr>
                <tr className="app_form_sb">
                    <td style={{ width: "100%", borderWidth: "0 0 2px 0" }}>
                        Appliances: ☐ Refrigerator &nbsp; ☐ Range/Oven &nbsp; ☐ Dishwasher &nbsp; ☐ Disposal &nbsp; ☐ Microwave &nbsp; ☐ Washer/Dryer &nbsp; ☐ Other
                        {/* (describe) */}
                    </td>
                </tr>
                {/* <tr className="app_form_sb">
                    <td>
                        Finished area above grade contains: Rooms: 8 &nbsp; Bedrooms: 3 &nbsp; Bath(s): 2 &nbsp; Square Feet of Gross Living Area Above Grade: 1,500 sq. ft.
                    </td>
                </tr> */}
                {/* <tr className="app_form_sb">
                    <td>
                        Additional features (special energy efficient items, etc.)
                    </td>
                </tr> */}
                {/* <tr className="app_form_sb"><td>&nbsp;</td></tr> */}
                {/* <tr className="app_form_sb">
                    <td>
                        Describe the condition of the property (including needed repairs, deterioration, renovations, remodeling, etc.).
                    </td>
                </tr> */}
                {/* <tr className="app_form_sb"><td>&nbsp;</td></tr>
                <tr className="app_form_sb"><td>&nbsp;</td></tr>
                <tr className="app_form_sb"><td>&nbsp;</td></tr>
                <tr className="app_form_sb"><td>&nbsp;</td></tr> */}
                <tr className="app_form_sb">
                    <td>
                        Are there any physical deficiencies or adverse conditions that affect the livability, soundness, or structural integrity of the property?
                        ☐ Yes &nbsp; ☐ No &nbsp;
                        {/* If Yes, describe */}
                    </td>
                </tr>
                {/* <tr className="app_form_sb"><td>&nbsp;</td></tr>
                <tr className="app_form_sb"><td>&nbsp;</td></tr>
                <tr className="app_form_sb"><td>&nbsp;</td></tr> */}
                <tr className="app_form_sb">
                    <td>
                        Does the property generally conform to the neighborhood (functional utility, style, condition, use, construction, etc.)?
                        ☐ Yes &nbsp; ☐ No &nbsp;
                        {/* If No, describe */}
                    </td>
                </tr>
                {/* <tr className="app_form_sb"><td>&nbsp;</td></tr>
                <tr className="app_form_sb"><td>&nbsp;</td></tr> */}
            </tbody>
        </table>
    )
}

export default ApImprovements