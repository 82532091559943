import React from 'react'

function ApSite() {
    return (
        <table border="2">
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={12}>S i t e</td>
                </tr>
                <tr className="app_form_sb">
                    <td>{`Dimensions: 40x60`}</td>
                    <td>{`Area: 2400 sq ft`}</td>
                    <td>{`Shape: Rectangular`}</td>
                    <td>{`View: Mountain`}</td>
                </tr>
                <tr className="app_form_sb">
                    <td>{`Specific Zoning Classification: Residential`}</td>
                    <td>{`Zoning Description: Allows single-family homes with certain height and lot size restrictions`}</td>
                    <td></td>
                </tr>
                <tr className="app_form_sb">
                    <td>
                        Zoning Compliance:
                        ☐ Legal &nbsp;
                        ☒ Legal Nonconforming (Grandfathered Use) &nbsp;
                        ☐ No Zoning &nbsp;
                        ☐ Illegal
                        {/* (describe) */}
                    </td>
                </tr>
                <tr className="app_form_sb">
                    <td>
                        Is the highest and best use of the subject property as improved (or as proposed per plans and specifications) the present use?
                        ☒ Yes &nbsp; ☐ No
                    </td>
                </tr>
                {/* <tr className='app_form_sb'>
                    <td>&nbsp;</td>
                </tr> */}
                <tr className='app_form_sb'>
                    <td>Utilities Public Other (describe)</td>
                    <td>Public Other
                        {/* (describe) */}
                    </td>
                    <td>Off-site Improvements—Type Public Private</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>Electricity  ☐ &nbsp; ☐</td>
                    <td>Water ☐ &nbsp; ☐</td>
                    <td>Street ☐ &nbsp; ☐</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>Gas  ☐ &nbsp; ☐</td>
                    <td>Sanitary Sewer ☐ &nbsp; ☐</td>
                    <td>Alley ☐ &nbsp; ☐</td>
                </tr>
                <tr className="app_form_sb">
                    <td>FEMA Special Flood Hazard Area: ☒ Yes ☐ No</td>
                    <td>FEMA Flood Zone: AE</td>
                    <td>FEMA Map #: 12345C5678D</td>
                    <td>FEMA Map Date: 08/15/2023</td>
                </tr>
                <tr className="app_form_sb">
                    <td>
                        Are the utilities and off-site improvements typical for the market area?
                        ☒ Yes ☐ No
                        {/* If No, describe */}
                    </td>
                </tr>
                <tr className="app_form_sb">
                    <td>
                        Are there any adverse site conditions or external factors (easements, encroachments, environmental conditions, land uses, etc.)?
                        ☐ Yes ☒ No
                        {/* If Yes, describe */}
                    </td>
                </tr>
                {/* <tr className='app_form_sb'>
                    <td>&nbsp;</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>&nbsp;</td>
                </tr>
                <tr className='app_form_sb'>
                    <td>&nbsp;</td>
                </tr> */}

            </tbody>
        </table>
    )
}

export default ApSite