import React, { useEffect, useState } from 'react'
import './SocialSetting.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import { Alert, FormControl, InputLabel, OutlinedInput, Snackbar } from '@mui/material'
import { useFormik } from 'formik'
import { getOtherProfileSetting, patchOtherProfileSetting } from '../../components/auth/AuthService'
import { LoadingSkeletonText } from '../../components/skeleton_loading/SkeletonLoading'
import SettingMobileNav from './SettingMobileNav'
import { MoreHoriz } from '@mui/icons-material'
function SocialSetting() {

    const [mobileSideBar, setMobileSidebar] = useState(false);

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('Updated');
    const [toastStatus, setToastStatus] = useState('success');

    const [loading, setLoading] = useState(true);

    const formik = useFormik({
        initialValues: {
            website_url: "",
            linkedin_profile: "",
            instagram_profile: "",
            twitter_profile: "",
            facebook_page: ""
        },
        onSubmit: async values => {
            try {
                await patchOtherProfileSetting(values)
                setToastText("Updated");
                setToastStatus("success");
                setToastShow(true);
            }
            catch (err) {
                // console.log(err);
                setToastText("Error");
                setToastStatus("warning");
                setToastShow(true);
            }
        },
    });

    useEffect(() => {
        try {
            getOtherProfileSetting()
                .then((response) => {
                    // console.log(response);
                    response.data.website_url && formik.setFieldValue("website_url", response.data.website_url);
                    response.data.linkedin_profile && formik.setFieldValue("linkedin_profile", response.data.linkedin_profile);
                    response.data.instagram_profile && formik.setFieldValue("instagram_profile", response.data.instagram_profile);
                    response.data.twitter_profile && formik.setFieldValue("twitter_profile", response.data.twitter_profile);
                    response.data.facebook_page && formik.setFieldValue("facebook_page", response.data.facebook_page);
                    setLoading(false);
                })
        }
        catch (err) {
            // console.log(err);
            setLoading(false);
        }
    }, [])


    useEffect(() => {
        const handleOutsideClick = (event) => {
          if (mobileSideBar && !event.target.closest('.setting_other_nav__mobile_active')) {
            setMobileSidebar(false);
          }
        };
    
        document.addEventListener('mousedown', handleOutsideClick);
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, [mobileSideBar]);
    

    return (
        <div className='social_setting'>
            <UserNav handleBurgerClick={() => setMobileSidebar(!mobileSideBar)}/>
            <div className='social_setting__container' style={{paddingTop:"5rem"}}>
                <SettingSideBar tab={"6"} />
                <div className='social_setting__main'>
                    <div className='social_setting__part'>
                        <h5>Social Media & Website</h5>
                        {
                            !loading ?
                                <div className='social_setting__inputs'>
                                    <div className='social_setting__inputs_sets'>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.facebook_page && Boolean(formik.errors.facebook_page)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Facebook Page</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Facebook Page"
                                                name="facebook_page"
                                                value={formik.values.facebook_page}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.linkedin_profile && Boolean(formik.errors.linkedin_profile)}>
                                            <InputLabel htmlFor="outlined-adornment-first">LinkedIn Profile</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="LinkedIn Profile"
                                                name="linkedin_profile"
                                                value={formik.values.linkedin_profile}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='social_setting__inputs_sets'>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.instagram_profile && Boolean(formik.errors.instagram_profile)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Instagram Profile</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Instagram Profile"
                                                name="instagram_profile"
                                                value={formik.values.instagram_profile}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.twitter_profile && Boolean(formik.errors.twitter_profile)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Twitter Profile</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Twitter Profile"
                                                name="twitter_profile"
                                                value={formik.values.twitter_profile}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='social_setting__inputs_sets'>

                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.website_url && Boolean(formik.errors.website_url)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Website URL</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Website URL"
                                                name="website_url"
                                                value={formik.values.website_url}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                :
                                <LoadingSkeletonText width={"100%"} height={"5rem"} />
                        }

                        <button className='social_setting__btn' onClick={() => formik.handleSubmit()}>Update</button>
                    </div>


                    {/* <div className='social_setting__part'>
                        <h5>Referral Code</h5>
                        <div className='social_setting__inputs'>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Membership Type' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='social_setting__btn' disabled>Update</button>
                    </div> */}

                </div>
            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={1500}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
            {
                mobileSideBar && <SettingMobileNav tab={"6"} isActive={mobileSideBar} setIsActive={setMobileSidebar}/>
            }
        </div>
    )
}

export default SocialSetting