import React from 'react'
import { apFormHeadTitle } from './ApConfiguration'

function ApTableContent() {
    return (
        <div className='final_report_container'>
            <div className='final_report_container2'>
                <div className='final_report_table_content'>
                    <div className='final_report_table_title'>{apFormHeadTitle}</div>
                    <img style={{ width: "30rem", height: "20rem" }} src='/images/apparaisal/ap_table_content.jpeg' alt='home' />
                    <div className='final_report_table_title'>DATE OF VALUATION:</div>
                    <div>03/11/2017</div>
                    <div className='final_report_table_title'>LOCATED AT:</div>
                    <div>Property Address</div>
                    <div>Legal Description</div>
                    <div>City, CA 11111</div>
                    <div className='final_report_table_title'>FOR:</div>
                    <div>The Bank</div>
                    <div>Bank Address, City, CA</div>
                    <div className='final_report_table_title'>TABLE OF CONTENTS:</div>
                    <div className="content_item"><span>Table of Contents/Cover Page</span> 1</div>
                    <div className="content_item"><span>URAR</span> 2</div>
                    <div className="content_item"><span>URAR</span> 3</div>
                    <div className="content_item"><span>URAR</span> 4</div>
                    <div className="content_item"><span>Location Map</span> 5</div>
                    <div className="content_item"><span>Subject Photos</span> 6</div>
                    <div className="content_item"><span>Comparable Photos 1-3</span> 7</div>
                    <div className="content_item"><span>Comparable Photos 4-6</span> 8</div>

                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>
    )
}

export default ApTableContent