import React from 'react'
import "./NewChatTulsa.css"

function NewChatTulsa({ setInput, selectedPrompt }) {

    const msg = "Enter your address or parcel ID to access the City of Guthrie's database of pre-reviewed development plans, available at no cost to developers. Only plans aligned with your parcel's zoning entitlements will be recommended, ensuring compliance with local regulations and expediting the approval process."

    return (
        <div className='copilot_tulsa_new_chat'>
            <div className='copilot_tulsa_new_chat__logo_area'>
                <img style={{maxWidth:"20rem", width:"10rem"}} src="/images/guthrie/guthrie_logo.png" alt='iqland' />
                <div className='title'>GAPP</div>
                <h3>Guthrie Approved Plans Program</h3>
                <div className='copilot_tulsa_new_chat_bot'>
                    {/* <div ><img className='copilot_tulsa_new_chat_bot_logo' src="/images/copilot_new/iq_profile_image.svg" alt='iqland' /></div> */}
                    <div className='copilot_tulsa_new_chat_instruction'>
                        <div style={{ fontSize: "1rem", fontWeight: "600" }}>How to Use the Guthrie Pre-Approved Plans Portal</div>
                        <div style={{ fontSize: "0.9rem", fontWeight: "400" }}>{msg}</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NewChatTulsa