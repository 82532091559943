import React from 'react'

function ApCostApproach() {
    return (
        <table border="2" style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={18}>C O S T &nbsp; A P P R O A C H</td>
                    <td colSpan={2} style={{ width: "100%", fontWeight: "bold", textAlign: "center", backgroundColor: "lightgray" }}>COST APPROACH TO VALUE (not required by Fannie Mae)</td>
                </tr>
                <tr>
                    <td colSpan={3} style={{ width: "100%" }}>Provide adequate information for the lender/client to replicate the below cost figures and calculations.</td>
                </tr>
                <tr>
                    <td colSpan={3} style={{ width: "100%" }}>Support for the opinion of site value (summary of comparable land sales or other methods for estimating site value)</td>
                </tr>
                <tr>
                    <td colSpan={3} style={{ width: "100%" }}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={3} style={{ width: "100%" }}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={3} style={{ width: "100%" }}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>ESTIMATED ☒ REPRODUCTION OR ☐ REPLACEMENT COST NEW</td>
                    <td style={{ width: "50%" }}>OPINION OF SITE VALUE = $34,532 </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        Source of cost data: Marshall & Swift
                    </td>
                    <td style={{ width: "50%" }}>
                        Dwelling 2,000 Sq. Ft. @ $150.00 = $300,000
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        Quality rating from cost service: Good
                        &nbsp;&nbsp;&nbsp;&nbsp;Effective date of cost data: 2024-01-01
                    </td>
                    <td style={{ width: "50%" }}>
                        Dwelling 2,000 Sq. Ft. @ $150.00 = $300,000
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        Comments on Cost Approach (gross living area calculations, depreciation, etc.)
                    </td>
                    <td style={{ width: "50%" }}>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        &nbsp;
                    </td>
                    <td style={{ width: "50%" }}>
                        Garage/Carport: 500 Sq. Ft. @ $100.00 = $50,000
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        &nbsp;
                    </td>
                    <td style={{ width: "50%" }}>
                        Total Estimate of Cost-New = $50,782.00
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        &nbsp;
                    </td>
                    <td style={{ width: "50%" }}>
                        <pre style={{ fontSize: "0.8rem", fontFamily: "poppins, san-sarif" }}>Less        Physical | Functional | External</pre>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        &nbsp;
                    </td>
                    <td style={{ width: "50%" }}>
                        None &nbsp;|&nbsp; None &nbsp;|&nbsp; None &nbsp;|&nbsp;&nbsp; = $(8,588.00)
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        &nbsp;
                    </td>
                    <td style={{ width: "50%" }}>
                        Depreciated Cost of Improvements = $87,899.00
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        &nbsp;
                    </td>
                    <td style={{ width: "50%" }}>
                        “As-is” Value of Site Improvements = $87,899.00
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        &nbsp;
                    </td>
                    <td style={{ width: "50%" }}>
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "50%", borderWidth: "0 1px 0 0" }}>
                        Estimated Remaining Economic Life (HUD and VA only) 8 Years
                    </td>
                    <td style={{ width: "50%" }}>
                        Indicated Value By Cost Approach = $85,889.00
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default ApCostApproach