import React, { useContext } from 'react'
import './LanDashboard.css'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import ActionSection from './components/action_section/ActionSection'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../components/auth/AuthenticationContext'
import { LoadingSkeletonText } from '../../components/skeleton_loading/SkeletonLoading'
import { useMediaQuery } from 'react-responsive'

function LanDashboard() {

  const navigate = useNavigate();

  const { user, loading } = useContext(AuthContext);

  const isTabScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className='lan_dashboard'>
      <NavBar />

      <div className='lan_copilot_hero'>
        <div className='lan_copilot_hero__left lan_container'>
          <div className='lan_title' data-aos="fade-right" style={{ color: "white", marginBottom: "1rem" }}>IQLAND's SmartBuild Dashboard: Revolutionizing Real Estate Development</div>
          <p className='lan_normal_text' data-aos="fade-right" style={{ color: "white" }}>IQLAND's SmartBuild simplifies development with tools that pair you with optimal architectural plans, estimate the cost of your projects, and analyze zoning regulations for you. Designed to drive efficiency and profitability for developers, architects, and investors, our platform helps you make informed decisions to bring your projects to life.</p>
        </div>
        <div className='lan_copilot_hero__right'>
          <img data-aos="fade-down" src={!isTabScreen? '/images/lan_home/dashboard_3.png': '/images/lan_agent/dashboard_agent.png'} alt='dashboard' />
        </div>
      </div>

      <div className='lan_architect__ex_marketplace'>
        <div className='lan_dashboard_why_texts'>
          <div className='lan_card__title' data-aos="fade-up" style={{ color: "var(--secondary-clr)" }}>Why IQLAND's SmartBuild ?</div>
          <div className='iqland_motto' data-aos="fade-up">The Benefits</div>
        </div>
        <div className='lan_architect__cards'>
          <div className='lan_architect__card' data-aos="fade-up" data-aos-duration="1700">
            <div className='lan_card__title'>Save Time and Resources</div>
            <p className='lan_normal_text'>No more waiting. Reduce the time and resources spent on research and analysis.</p>
          </div>
          <div className='lan_architect__card' data-aos="fade-up" data-aos-duration="2200" style={{ backgroundColor: "var(--secondary-clr)" }}>
            <div className='lan_card__title' style={{ color: "var(--primary-clr)" }}>Maximize Returns</div>
            <p className='lan_normal_text'>With our SmartBuild, you'll be able to identify opportunities to maximize returns and optimize your development projects.</p>
          </div>
          <div className='lan_architect__card' data-aos="fade-up" data-aos-duration="1900">
            <div className='lan_card__title'>Data-Driven Decisions</div>
            <p className='lan_normal_text'>Make informed decisions with our AI-driven insights, backed by a vast database of real estate intelligence.</p>
          </div>
        </div>

      </div>

      <div className='lan_dashboard_plan'>
        <div className='lan_dashboard_plan_left'>
          <div className='iqland_motto' style={{ color: "white" }} data-aos="fade-up">Key Features</div>
          <p className='lan_normal_text' style={{ color: "white" }} data-aos="fade-right">IQLAND's SmartBuild unlocks data-driven decision making for your real estate developments. Its features are designed to help you maximize returns while saving time and resources.</p>
          <div>

            <Accordion className='lan_dashboard_plan_left_acc' data-aos="fade-right">
              <AccordionSummary
                sx={{ borderStyle: "solid", borderWidth: "0 0 1px 0", borderColor: "white", padding: "0" }}
                expandIcon={<ExpandMoreOutlined sx={{ border: "1px solid white", borderRadius: "50%", color: "white" }} />}
              >
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Profitability Estimation</div>
              </AccordionSummary>
              <AccordionDetails
                sx={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className='lan_normal_text' style={{ color: "white" }}>Get accurate ROI estimations for your parcels of interest, helping you prioritize projects and allocate resources effectively.</div>
              </AccordionDetails>
            </Accordion>
            <Accordion className='lan_dashboard_plan_left_acc' data-aos="fade-right">
              <AccordionSummary
                sx={{ borderStyle: "solid", borderWidth: "0 0 1px 0", borderColor: "white", padding: "0" }}
                expandIcon={<ExpandMoreOutlined sx={{ border: "1px solid white", borderRadius: "50%", color: "white" }} />}
              >
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Visualize Parcel and Zoning Data</div>
              </AccordionSummary>
              <AccordionDetails
                sx={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className='lan_normal_text' style={{ color: "white" }}>Display maps of your parcels and view key zoning information, including setbacks, parcel boundaries, and more. Get a clear understanding of your project's spatial context and make more informed decisions.</div>
              </AccordionDetails>
            </Accordion>
            <Accordion className='lan_dashboard_plan_left_acc' data-aos="fade-right">
              <AccordionSummary
                sx={{ borderStyle: "solid", borderWidth: "0 0 1px 0", borderColor: "white", padding: "0" }}
                expandIcon={<ExpandMoreOutlined sx={{ border: "1px solid white", borderRadius: "50%", color: "white" }} />}
              >
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Architectural Design Recommendations</div>
              </AccordionSummary>
              <AccordionDetails
                sx={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className='lan_normal_text' style={{ color: "white" }}>Get access to relevant architectural design plans tailored to your parcel dimensions and zoning restrictions. Filter designs by area, footprint dimensions, height, building quality, and more.</div>
              </AccordionDetails>
            </Accordion>
            <Accordion className='lan_dashboard_plan_left_acc' data-aos="fade-right">
              <AccordionSummary
                sx={{ borderStyle: "solid", borderWidth: "0 0 1px 0", borderColor: "white", padding: "0" }}
                expandIcon={<ExpandMoreOutlined sx={{ border: "1px solid white", borderRadius: "50%", color: "white" }} />}
              >
                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Comparable Sales</div>
              </AccordionSummary>
              <AccordionDetails
                sx={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div className='lan_normal_text' style={{ color: "white" }}>Obtain to a list of comparable sales to inform your development decisions and ensure your competitivity in the market.</div>
              </AccordionDetails>
            </Accordion>

          </div>

          {
            !loading ?
              <div
                className='lan_btn_gold' data-aos="fade-up" style={{ color: "var(--primary-clr)" }}
                onClick={() => user ? navigate("/dashboard") : window.location.href = "/register"}>
                Get Started
              </div>
              :
              <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
          }
        </div>
        <div className='lan_dashboard_plan_right' data-aos="fade-left">
          <img src='/images/lan_home/dashboard_2.png' alt='dashboard' />
        </div>
      </div>

      <div className='lan_dashboard__our_stats lan_container'>
        <div className='lan_title' style={{ color: "var(--primary-clr)" }} data-aos="fade-down">Our Stats</div>
        <div className='lan_dashboard__our_stats_cards'>
          <div className='lan_dashboard__our_stats_card'>
            <div className='lan_state_big_num' data-aos="fade-up" data-aos-duration="1500">30k+</div>
            <hr />
            <div className='lan_state_bottom_text' data-aos="fade-up" data-aos-duration="1800">Architectural designs</div>
          </div>
          <div className='lan_dashboard__our_stats_card'>
            <div className='lan_state_big_num' data-aos="fade-up" data-aos-duration="2000">500k+</div>
            <hr />
            <div className='lan_state_bottom_text' data-aos="fade-up" data-aos-duration="1500">Parcels</div>
          </div>
          <div className='lan_dashboard__our_stats_card'>
            <div className='lan_state_big_num' data-aos="fade-up" data-aos-duration="1500">2</div>
            <hr />
            <div className='lan_state_bottom_text' data-aos="fade-up" data-aos-duration="1500">Major cities</div>
          </div>
          <div className='lan_dashboard__our_stats_card'>
            <div className='lan_state_big_num' data-aos="fade-up" data-aos-duration="2000">5+</div>
            <hr />
            <div className='lan_state_bottom_text' data-aos="fade-up" data-aos-duration="1000">Cutting-edge AI models</div>
          </div>
        </div>
      </div>

      <div className='lan_dashboard__transform lan_container'>
        <div className='iqland_motto' style={{ color: "white" }} data-aos="fade-right" data-aos-duration="1600">Transform Your Development Process</div>
        <p className='lan_normal_text' style={{ color: "white" }} data-aos="fade-right" data-aos-duration="2000">IQLAND's Interactive SmartBuild dashboard is more than just a tool; it's a strategic asset that puts comprehensive planning capabilities at your fingertips. Know your zoning, pick your plans, estimate your costs, and predict your profit—all in minutes, not months. This unparalleled efficiency not only saves time and resources but also empowers you to make decisions with unparalleled confidence and clarity.</p>
      </div>

      <ActionSection />
      <JoinIqlandToday />
      <Footer />
    </div>
  )
}

export default LanDashboard