// export const preMessage = [
//     {
//       id:1,
//       title:"Subdivide a lot",
//       content:"Plot a maximized parcel subdivision for a plot of land."
//     },
//     {
//       id:2,
//       title:"Land use entitlements",
//       content:"What type of structure can I build on a parcel per local zoning regulations. FAR"
//     },
//     {
//       id:3,
//       title:"Find a home for me to build",
//       content:"Explore new construction home plans that qualify for your parcel. Modern, Craftsman, "
//     },
//     {
//       id:4,
//       title:"Run a feasibility report",
//       content:"What is the best scenario for making the most profit from this parcel?"
//     },
//     {
//       id:5,
//       title:"Local Construction costs",
//       content:"What is the price to build per square foot for a luxury, custom or builder grade home?"
//     },
//     {
//       id:6,
//       title:"Check my plans",
//       content:"Upload your construction or site plans to pre-determine legal compliance."
//     },
//   ]

  export const preMessage = [
    {
      id:1,
      title:"Zoning IQ",
      content:"Zoning type, Setbacks, FAR, Lot Coverage, Height and other zoning parameters",
      text:"See what’s allowed on your land instantly—zoning code, building types, and a preliminary site plan.",
      address_box:"Address/Parcel ID"
    },
    {
      id:2,
      title:"Appraisal IQ",
      content:"Estimate the value of a residential property or parcel",
      text:"Estimate the value of a residential property or parcel",
      address_box:"Address/Parcel ID"
    },
    {
      id:3,
      title:"LandSearch IQ",
      content:"Find unlisted or listed vacant residential parcels",
      text:"Find unlisted or listed vacant residential parcels",
      address_box:"Zipcode"
    },
    
    {
      id:5,
      title:"HomeSearch IQ",
      content:"Find a move-in-ready home in [your city] with high long-term rental potential.",
      text:"Quickly locate primary homes, rentals, and investment opportunities with real-time insights for smarter prospecting.",
      address_box:"Enter a question to begin your property search"
    },
    {
      id:6,
      title:"Subdivide a Parcel",
      content:"Zoning type, Setbacks, FAR, Lot Coverage, Height and other zoning parameters",
      text:"Discover if your parcel can be split or rezoned, and preview site plans instantly. For parcels below 100,000 sq.ft.",
      address_box:"Address/Parcel ID"
    },
    {
      id:4,
      title:"Construction Costs",
      content:"Local building costs for new construction",
      text:"Local building costs for new construction",
      address_box:"Address/Parcel ID"
    },
  ]