import React, { useEffect, useState } from "react";
import "./BillingSetting.css";
import UserNav from "../../components/user_nav/UserNav";
import SettingSideBar from "./SettingSideBar";
import {
  cancelSubscription,
  checkSubscriptionNavigationStatus,
  getSubscriptionDetails,
} from "../../components/auth/AuthService";
import { LinearProgress } from "@mui/material";
import { LoadingSkeleton } from "../../components/skeleton_loading/SkeletonLoading";
import UpdatePaymentMethod from "../checkout/UpdatePaymentMethod";
import SettingMobileNav from "./SettingMobileNav";

function BillingSetting(props) {


  // convert to currency
  function formatCurrency(number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(number);
  }

  const { stripePromise } = props;

  const [mobileSideBar, setMobileSidebar] = useState(false);


  const [subsDetails, setSubsDetails] = useState();
  const [trialDays, setTrialDays] = useState();
  const [renewDate, setRenewDate] = useState();
  const [lastRenewData, setLastRenewDate] = useState();
  const [subsId, setSubsId] = useState();
  const [loading, setLoading] = useState(true);

  const [cancelPop, setCancelPop] = useState(false);

  async function handleCancelSubscription(subscriptionId) {
    try {
      await cancelSubscription(subscriptionId);
      window.location.reload();
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    checkSubscriptionNavigationStatus().then((data) => {
      // console.log(data);
      setSubsId(data.subscription_id);
      if (data.has_active_subscription) {
        getSubscriptionDetails(data.subscription_id).then((data) => {
          // console.log(data.subscription);
          setSubsDetails(data.subscription);
          const trialStart = new Date();
          const trialEnd = new Date(data.subscription.trial_end);
          const trialDaysCalculated = Math.ceil(
            (trialEnd - trialStart) / (1000 * 60 * 60 * 24)
          );
          setTrialDays(trialDaysCalculated);

          const currentPeriodEndStr = data.subscription.current_period_end;
          const currentPeriodEnd = new Date(currentPeriodEndStr);
          const formattedEndDate = currentPeriodEnd.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          );

          const lastRenewDateStr = data.subscription.current_period_start;
          const lastRenewDate = new Date(lastRenewDateStr);
          const formattedLastRenewDate = lastRenewDate.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          );
          setLastRenewDate(formattedLastRenewDate);
          setRenewDate(formattedEndDate);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (mobileSideBar && !event.target.closest('.setting_other_nav__mobile_active')) {
        setMobileSidebar(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [mobileSideBar]);

  return (
    <div className="billing_setting">
      <UserNav handleBurgerClick={() => setMobileSidebar(!mobileSideBar)} />
      <div className="billing_setting__container" style={{paddingTop:"5rem"}}>
        <SettingSideBar tab={"5"} />
        <div className="billing_setting__main">
          <div className="billing_setting__part">
            <h5>Subscription</h5>
            {!loading ? (
              subsDetails ? (
                <div className="my_subs">
                  <div className="my_subs__top">
                    <div className="my_subs__plan_title">
                      {subsDetails.plan_name}
                    </div>
                    <div className="my_subs__plan_price">
                      {formatCurrency(subsDetails.amount)}
                      /{subsDetails.plan_interval}
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Trial Remaining</div>
                    <div>{trialDays ? trialDays : 0} Days</div>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    value={trialDays ? (trialDays / 7) * 100 : 0}
                    sx={{
                      backgroundColor: "var(--secondary-dark-clr)",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "var(--secondary-clr)",
                      },
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0 1rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>Last Billed Date: {lastRenewData}</div>
                    <div>Next Billing Date: {renewDate}</div>
                  </div>
                  <div>
                    Renew:{" "}
                    {subsDetails.collection_method === "charge_automatically"
                      ? "Automatic"
                      : subsDetails.collection_method}
                  </div>
                  <div
                    className="lan_btn_gold"
                    style={{ textWrap: "nowrap", alignSelf: "flex-end" }}
                    onClick={() => setCancelPop(true)}
                  >
                    Cancel Subscription
                  </div>
                </div>
              ) : (
                <div
                  className="lan_primary_button"
                  onClick={() => (window.location.href = "/pricing")}
                >
                  Subscribe Now
                </div>
                
              )
            ) : (
              <LoadingSkeleton width={"100%"} />
            )}
          </div>
          <UpdatePaymentMethod stripePromise={stripePromise} />
          {/* <div className="billing_setting__part">
            <div className="billing_setting__inputs">
              <div className="billing_setting__inputs_sets">
                <IQTextboxLabel
                  label="Name on Card"
                  disabled={true}
                  value={text}
                  setText={() => setText()}
                  placeholder={"Not Available"}
                />
                <IQTextboxLabel
                  label="Card Number"
                  disabled={true}
                  value={text}
                  setText={() => setText()}
                  placeholder={"Not Available"}
                />
              </div>
              <div className="billing_setting__inputs_sets">
                <IQTextboxLabel
                  label="Expiry Date"
                  disabled={true}
                  value=""
                  placeholder={"Not Available"}
                />
                <IQTextboxLabel
                  label="CVV"
                  disabled={true}
                  value={text}
                  setText={() => setText()}
                  placeholder={"Not Available"}
                />
              </div>
            </div>
            <button className="billing_setting__btn" disabled>
              Update
            </button>
          </div> */}
        </div>
      </div>
      {cancelPop && (
        <div className="cancel_subs_popup">
          <div className="cancel_subs_popup__container">
            <div>Do you want to cancel subscription?</div>
            <div className="cancel_subs_popup__btns">
              <div
                className="lan_primary_button"
                onClick={() => handleCancelSubscription(subsId)}
              >
                Yes
              </div>
              <div
                className="lan_primary_button"
                onClick={() => setCancelPop(false)}
              >
                No
              </div>
            </div>
          </div>
        </div>
      )}

      <SettingMobileNav isActive={mobileSideBar} setIsActive={setMobileSidebar} tab={"5"} />
    </div>
  );
}

export default BillingSetting;
