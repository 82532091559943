import React from 'react'
import ApAddComments from './ApAddComments'
import ApCostApproach from './ApCostApproach'
import ApIncome from './ApIncome'
import ApPud from './ApPud'
import { apFormHeadTitle } from './ApConfiguration'

function FinalReportPage3() {
    return (
        <div className='final_report' style={{padding:"2rem"}}>
            <div className='final_report_title'>{apFormHeadTitle}</div>
            <ApAddComments/>
            <ApCostApproach/>
            <ApIncome/>
            <ApPud/>
        </div>
    )
}

export default FinalReportPage3