import React from 'react'
import './UserInputMsgPop.css'

function UserInputMsgPop({setShowSortToast, sorting}) {
    return (
        <div className='user_input_msg_popup'>
            <div className='user_input_msg_container'>
                <div className='user_input_msg_text'>Sorting by equity will take 1-2 minutes. Do you want to proceed?</div>
                <div className='user_input_msg_buttons'>
                    <div className='user_input_msg_button' onClick={()=> {sorting("equity"); setShowSortToast(false)}}>Yes</div>
                    <div className='user_input_msg_button' onClick={()=> setShowSortToast(false)}>No</div>
                </div>
            </div>
        </div>
    )
}

export default UserInputMsgPop