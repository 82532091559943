import React from 'react'

function ApLocationMap() {
  return (
    <div style={{padding:"1.5rem",  display:"flex", flexDirection:"column", gap:"1rem"}}>
        <div className='final_report_table_title'>Location Map</div>
        <table border={1}>
            <tbody>
                <tr style={{width:"100%"}}>
                    <td colSpan={4}>Borrower: Jhon</td>
                </tr>
                <tr style={{width:"100%"}}>
                    <td colSpan={4}>Property Address: 123, asdada, tulsa</td>
                </tr>
                <tr style={{width:"100%"}}>
                    <td>City: Tulsa</td>
                    <td>County: Tulsa</td>
                    <td>State: Oklohoma</td>
                    <td>Zip Code: 1111</td>
                </tr>
                <tr style={{width:"100%"}}>
                    <td colSpan={4}>Lender/Client: The Bank</td>
                </tr>
            </tbody>
        </table>
        <img src="/images/apparaisal/ap_map.jpeg" alt='map'/>
    </div>
  )
}

export default ApLocationMap