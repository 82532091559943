import React from 'react'

function ApAddComments() {
    return (
        <table border="2" style={{width:"100%"}}>
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={38}>A D D I T I O N A L &nbsp;&nbsp; C O M M E N T S</td>
                    <td style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2} style={{width:"100%"}}>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    )
}

export default ApAddComments