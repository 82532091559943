import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

function MDropDown({label, values, texts, value, setValue}) {
    return (
        <FormControl sx={{flexShrink: 0, flexGrow:"1"}}>
            <InputLabel sx={{
                    '&.Mui-focused': {
                        color: 'var(--primary-clr)',
                    },
                }} id="demo-simple-select-label">{label}</InputLabel>
            <Select
                sx={{
                    height: "2.5rem",
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--primary-clr)',
                    },
                }}
                onClick={(e) => e.stopPropagation()}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                onChange={(e) => {setValue(e.target.value); }}
            >
                {
                    values.map((value, index) => (
                        <MenuItem key={index} value={value}>{texts[index]}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default MDropDown