import { CloseOutlined } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './SettingMobileNav.css'

function SettingMobileNav({ isActive, setIsActive, tab }) {

    const navigate = useNavigate()
    return (
        <div className={`setting_other_nav ${isActive ? "setting_other_nav__mobile_active" : ""}`}>
            <div className='co_sidebar__mobile'>
                <ul style={{ paddingTop: "4.125rem" }}>
                    <li className='co_sidebar__mobile__close' onClick={() => (setIsActive(!isActive))}><CloseOutlined /></li>
                    <li onClick={() => navigate("/user/home")}>Home</li>
                    <li onClick={() => navigate("/copilot")}>Copilot</li>
                    <li onClick={() => navigate("/dashboard")}>SmartBuild</li>
                    <li onClick={() => navigate("/appraisal")}>Appraisal</li>
                    <li onClick={() => navigate("/reports")}>Reports</li>
                </ul>
                <div style={{ marginTop: "2rem", marginBottom: "1rem", color: "#777" }}>Settings</div>
                <ul>
                    <li onClick={() => navigate("/settings/personal")} style={tab === "1" ? { backgroundColor: "var(--secondary-clr)", paddingLeft: "1rem" } : { paddingLeft: "1rem" }}>Personal Information</li>
                    <li onClick={() => navigate("/settings/professional")} style={tab === "2" ? { backgroundColor: "var(--secondary-clr)", paddingLeft: "1rem" } : { paddingLeft: "1rem" }}>Professional Information</li>
                    <li onClick={() => navigate("/settings/geographic")} style={tab === "3" ? { backgroundColor: "var(--secondary-clr)", paddingLeft: "1rem" } : { paddingLeft: "1rem" }}>Geographic Details</li>
                    <li onClick={() => navigate("/settings/security")} style={tab === "4" ? { backgroundColor: "var(--secondary-clr)", paddingLeft: "1rem" } : { paddingLeft: "1rem" }}>Security & Password</li>
                    <li onClick={() => navigate("/settings/billing")} style={tab === "5" ? { backgroundColor: "var(--secondary-clr)", paddingLeft: "1rem" } : { paddingLeft: "1rem" }}>Billing & Payment details</li>
                    <li onClick={() => navigate("/settings/social")} style={tab === "6" ? { backgroundColor: "var(--secondary-clr)", paddingLeft: "1rem" } : { paddingLeft: "1rem" }}>Social Media & Website</li>

                </ul>
            </div>
        </div>
    )
}

export default SettingMobileNav