import React, { useEffect } from 'react'
import './PersonalInfo.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { getOtherProfileSetting, getUserSetting, patchOtherProfileSetting, updateProfileSetting } from '../../components/auth/AuthService'
import { Alert, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Snackbar } from '@mui/material'
import { LoadingSkeletonText } from '../../components/skeleton_loading/SkeletonLoading'
import { capitalize } from 'lodash'
import SettingMobileNav from './SettingMobileNav'
import { getProfilePicture, uploadProfilePicture } from '../../components/user_service/UserService'
import { CheckOutlined, CloseOutlined } from '@mui/icons-material'
import imageCompression from 'browser-image-compression'

function PersonalInfo() {

    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(); // Default profile image
    const [preProfileImg, setPreProfileImg] = useState();
    const [imageLoading, setImageLoading] = useState(false);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];

        // Check if the file is an image
        if (selectedFile && selectedFile.type.startsWith('image/')) {

            const options = {
                maxSizeMB: 0.6, // Max file size in MB
                maxWidthOrHeight: 400, // Max width/height
                useWebWorker: true, // Enable web worker for faster compression
            };

            // Compress the image
            const compressedFile = await imageCompression(selectedFile, options);

            setFile(compressedFile);
            setPreview(URL.createObjectURL(compressedFile)); // Update profile image preview

        } else {
            setFile(null);
            setPreview(preProfileImg); // Reset to default if not an image
            setToastText("Invalid image");
            setToastStatus("error");
            setToastShow(true);
        }
    };

    const handlePlusIconClick = () => {
        document.getElementById('fileInput').click(); // Trigger file input on plus icon click
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) return;
        setImageUploadLoading(true)
        const formData = new FormData();
        formData.append('profile_pic', file);

        try {
            await uploadProfilePicture(formData)
            setToastText("Profile picture updated");
            setToastStatus("success");
            setToastShow(true);
            setFile(null)
            setImageUploadLoading(false)
        } catch (error) {
            // console.error('Error uploading the image:', error);
            setToastText("Error uploading the image");
            setToastStatus("error");
            setToastShow(true);
            setFile(null)
            setImageUploadLoading(false)
        }
    };

    const [mobileSideBar, setMobileSidebar] = useState(false);


    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('Updated');
    const [toastStatus, setToastStatus] = useState('success');

    // edit profile loading 
    const [loadingEP, setLoadingEP] = useState(false);

    const formik_edit_profile = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: ''
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
        }),
        onSubmit: async (values) => {
            await updateProfileSetting(values.first_name, values.last_name, values.phone);
            setToastText("Updated");
            setToastStatus("success");
            setToastShow(true);
        },
    });

    const home_style = [
        "None",
        "Bungalow",
        "Craftsman",
        "Coastal",
        "Beach",
        "Coastal Contemporary",
        "French Country",
        "Acadian",
        "Mediterranean",
        "Spanish",
        "Southwest",
        "Adobe",
        "Pueblo",
        "Tuscan",
        "Florida",
        "Mountain",
        "Hill Country",
        "Modern Farmhouse",
        "New American",
        "Scandinavian",
        "Transitional",
        "Contemporary",
        "Modern",
        "Prairie",
        "Mid-Century Modern",
        "Rustic",
        "Log",
        "Cabin",
        "Farmhouse",
        "Cottage",
        "Barndominium",
        "Ranch",
        "Southern",
        "Southern Traditional",
        "Low Country",
        "Colonial",
        "Georgian",
        "Traditional",
        "European",
        "Neoclassical",
        "Cape Cod", "Townhouse",
        "Row House",
        "Carriage",
        "A-Frame",
        "Victorian",
        "Tudor",
        "Queen Anne"


    ];
    const construction_material = ['None', '2x6', '2x4', '2x8', 'Block', 'Metal', 'ICF', 'Log', 'Block / CMU (main floor)', '2x4 or 2x6', '2x4 and 2x6', 'Block / CMU (main floor) or 2x6', '2x6 or Block / CMU (main floor)'];
    const property_type = ["None", "Single Family", "Duplex", "Multi Family", "Other"];

    const formik_preferences = useFormik({
        initialValues: {
            preferred_property_type: "None",
            preferred_home_style: "None",
            preferred_construction_material: "None"
        },

        onSubmit: async (values) => {
            await patchOtherProfileSetting(values);
            setToastText("Updated");
            setToastStatus("success");
            setToastShow(true);
        }
    })






    useEffect(() => {
        setLoadingEP(true);
        setImageLoading(true);
        try {
            getProfilePicture()
                .then((response) => {
                    setPreview(response.presigned_url);
                    setPreProfileImg(response.presigned_url)
                    setImageLoading(false);
                })
        }
        catch (error) {
            setPreview('/images/default_profile.png');
            setPreProfileImg('/images/default_profile.png')
            setImageLoading(false);
        }
        getUserSetting()
            .then((response) => {

                // console.log(response);
                formik_edit_profile.setFieldValue('first_name', capitalize(response.data.first_name));
                formik_edit_profile.setFieldValue('last_name', capitalize(response.data.last_name));
                formik_edit_profile.setFieldValue('email', response.data.email);
                formik_edit_profile.setFieldValue('phone', capitalize(response.data.phone));
                setLoadingEP(false)
            })

        getOtherProfileSetting()
            .then((response) => {
                formik_preferences.setFieldValue('preferred_property_type', response.data.preferred_property_type);
                formik_preferences.setFieldValue('preferred_home_style', response.data.preferred_home_style);
                formik_preferences.setFieldValue('preferred_construction_material', response.data.preferred_construction_material);
            })

    }, [])

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (mobileSideBar && !event.target.closest('.setting_other_nav__mobile_active')) {
                setMobileSidebar(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [mobileSideBar]);


    return (
        <div className='personal_info'>
            <UserNav handleBurgerClick={() => setMobileSidebar(!mobileSideBar)} />
            <div className='personal_info__container' style={{paddingTop:"5rem"}}>
                <SettingSideBar tab={"1"} />
                <div className='personal_info__main'>
                    <div className='personal_info__part'>
                        <h5>Edit Profile</h5>
                        <div className='personal_info__profile'>

                            {
                                !imageUploadLoading ?
                                    (
                                        imageLoading ?
                                            <img src="/images/default_profile.png" alt='profile' className='profile_img' />
                                            :
                                            <img src={preview} alt='profile' className='profile_img' />
                                    )
                                    :
                                    <img src="/images/spinner.gif" alt='profile' className='profile_img' />
                            }
                            {
                                !file ?
                                    <img onClick={handlePlusIconClick} className='camera' src='/images/setting/photo_icon.svg' alt='i' />
                                    :
                                    !imageUploadLoading && <div className='profile_img_actions'>
                                        <div onClick={handleSubmit}>
                                            <CheckOutlined
                                                className='profile_ok'

                                            />
                                        </div>
                                        <div onClick={() => { setFile(null); setPreview(preProfileImg) }}>
                                            <CloseOutlined
                                                className='profile_close'
                                            />
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            !loadingEP ?
                                <div className='personal_info__inputs'>
                                    <div className='personal_info__inputs_sets'>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik_edit_profile.touched.first_name && Boolean(formik_edit_profile.errors.first_name)}>
                                            <InputLabel htmlFor="outlined-adornment-first">First Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="First Name"
                                                name="first_name"
                                                value={formik_edit_profile.values.first_name ? formik_edit_profile.values.first_name : ""}
                                                onChange={formik_edit_profile.handleChange}
                                                onBlur={formik_edit_profile.handleBlur}
                                            />
                                            {formik_edit_profile.touched.first_name && formik_edit_profile.errors.first_name ? (
                                                <FormHelperText>{formik_edit_profile.errors.first_name}</FormHelperText>
                                            ) : null}
                                        </FormControl>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik_edit_profile.touched.last_name && Boolean(formik_edit_profile.errors.last_name)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Last Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Last Name"
                                                name="last_name"
                                                value={formik_edit_profile.values.last_name && (formik_edit_profile.values.last_name && formik_edit_profile.values.last_name !== "Na") ? formik_edit_profile.values.last_name : ""}
                                                onChange={formik_edit_profile.handleChange}
                                            />
                                            {formik_edit_profile.touched.last_name && formik_edit_profile.errors.last_name ? (
                                                <FormHelperText>{formik_edit_profile.errors.last_name}</FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                    <div className='personal_info__inputs_sets'>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik_edit_profile.touched.email && Boolean(formik_edit_profile.errors.email)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Email</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="email"
                                                label="Email"
                                                name="email"
                                                disabled
                                                value={formik_edit_profile.values.email ? formik_edit_profile.values.email : ""}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={formik_edit_profile.touched.phone && Boolean(formik_edit_profile.errors.phone)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Phone Number</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="tel"
                                                label="Phone Number"
                                                name="phone"
                                                value={formik_edit_profile.values.phone ? formik_edit_profile.values.phone : ""}
                                                onChange={formik_edit_profile.handleChange}
                                            />
                                            {formik_edit_profile.touched.phone && formik_edit_profile.errors.phone ? (
                                                <FormHelperText>{formik_edit_profile.errors.phone}</FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                                :
                                <LoadingSkeletonText width={"100%"} height={"5rem"} />
                        }
                        <button className='personal_info__btn' onClick={() => formik_edit_profile.handleSubmit()}>Update</button>
                    </div>

                    <div className='personal_info__part'>
                        <h5>Preferences & Interests</h5>
                        {
                            !loadingEP ?
                                <div className='personal_info__inputs'>
                                    <div className='personal_info__inputs_sets'>

                                        <FormControl sx={{ flexShrink: 0, flexGrow: "1" }}>
                                            <InputLabel sx={{
                                                '&.Mui-focused': {
                                                    color: 'var(--primary-clr)',
                                                },
                                            }} id="demo-simple-select-label">Preferred property types</InputLabel>
                                            <Select
                                                sx={{
                                                    height: "3rem",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'var(--primary-clr)',
                                                    },
                                                }}

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formik_preferences.values.preferred_property_type ? formik_preferences.values.preferred_property_type : "None"}
                                                label="Preferred property types"
                                                onChange={(e) => formik_preferences.setFieldValue('preferred_property_type', e.target.value)}
                                            >
                                                {
                                                    property_type.map((value, index) => (
                                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ flexShrink: 0, flexGrow: "1" }}>
                                            <InputLabel sx={{
                                                '&.Mui-focused': {
                                                    color: 'var(--primary-clr)',
                                                },
                                            }} id="demo-simple-select-label">Home style preferences</InputLabel>
                                            <Select
                                                sx={{
                                                    height: "3rem",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'var(--primary-clr)',
                                                    },
                                                }}

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formik_preferences.values.preferred_home_style ? formik_preferences.values.preferred_home_style : "None"}
                                                label="Home style preferences"
                                                onChange={(e) => formik_preferences.setFieldValue('preferred_home_style', e.target.value)}
                                            >
                                                {
                                                    home_style.map((value, index) => (
                                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='personal_info__inputs_sets'>
                                        <FormControl sx={{ flexShrink: 0, flexGrow: "1" }}>
                                            <InputLabel sx={{
                                                '&.Mui-focused': {
                                                    color: 'var(--primary-clr)',
                                                },
                                            }} id="demo-simple-select-label">Construction material preference</InputLabel>
                                            <Select
                                                sx={{
                                                    height: "3rem",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'var(--primary-clr)',
                                                    },
                                                }}

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formik_preferences.values.preferred_construction_material ? formik_preferences.values.preferred_construction_material : "None"}
                                                label="Construction material preference"
                                                onChange={(e) => formik_preferences.setFieldValue('preferred_construction_material', e.target.value)}
                                            >
                                                {
                                                    construction_material.map((value, index) => (
                                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                :
                                <LoadingSkeletonText width={"100%"} height={"5rem"} />
                        }
                        <button className='personal_info__btn' onClick={() => formik_preferences.handleSubmit()}>Update</button>
                    </div>

                    {/* <div className='personal_info__part'>
                        <h5>User Interactions & Date</h5>
                        <div className='personal_info__inputs'>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='Favorited / Bookmarked Listings' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='personal_info__btn' disabled>Update</button>
                    </div>

                    <div className='personal_info__part'>
                        <h5>Referral Code</h5>
                        <div className='personal_info__inputs'>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='Membership Type' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='personal_info__btn' disabled>Update</button>
                    </div> */}

                </div>
            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={1500}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
            <SettingMobileNav tab={"1"} isActive={mobileSideBar} setIsActive={setMobileSidebar} />


            {/* Hidden File Input */}
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
            />
        </div>
    )
}

export default PersonalInfo