import React from 'react'

function ApSales() {
    return (
        <table border="2" style={{ overflowX: "auto" }}>
            <tbody>
                <tr>
                    <td className='vertical-text' rowSpan={62}>S a l e s &nbsp; C o m p a r i s i o n &nbsp; A p p r o a c h</td>
                    <td colSpan={9}>There are comparable properties currently offered for sale in the subject neighborhood ranging in price from $300,000 to $450,000.</td>
                </tr>
                <tr>
                    <td colSpan={9}>There are 5 comparable sales in the subject neighborhood within the past twelve months ranging in sale price from $250,000 to $400,000.</td>
                </tr>
                <tr className='sales_table_tr sales_table_tr_head' >
                    <td colSpan={2}>FEATURE</td>
                    <td>SUBJECT</td>
                    <td colSpan={2}>COMPARABLE SALE #1</td>
                    <td colSpan={2}>COMPARABLE SALE #2</td>
                    <td colSpan={2}>COMPARABLE SALE #3</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={3}>Address</td>
                    <td colSpan={2}>123 Main St, Anytown, USA</td>
                    <td colSpan={2}>456 Elm St, Anytown, USA</td>
                    <td colSpan={2}>789 Oak St, Anytown, USA</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Proximity to Subject</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td colSpan={2}>0.5 miles</td>
                    <td colSpan={2}>1 mile</td>
                    <td colSpan={2}>0.8 miles</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Sale Price</td>
                    <td>$350,000</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>$340,000</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>$355,000</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>$360,000</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Sale Price/Gross Liv. Area</td>
                    <td>$175/sq. ft.</td>
                    <td>$168/sq. ft.</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>$180/sq. ft.</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>$172/sq. ft.</td>
                    <td className='disabled_td'>&nbsp;</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Data Source(s)</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td colSpan={2}>MLS</td>
                    <td colSpan={2}>Realtor.com</td>
                    <td colSpan={2}>Zillow</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Verification Source(s)</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td colSpan={2}>Agent Confirmation</td>
                    <td colSpan={2}>Public Records</td>
                    <td colSpan={2}>Tax Records</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>VALUE ADJUSTMENTS</td>
                    <td>DESCRIPTION</td>
                    <td>DESCRIPTION</td>
                    <td>+(-) $ Adjustment</td>
                    <td>DESCRIPTION</td>
                    <td>+(-) $ Adjustment</td>
                    <td>DESCRIPTION</td>
                    <td>+(-) $ Adjustment</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Sale or Financing Concessions</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>Concession of 3%</td>
                    <td>- $5,000</td>
                    <td>No Concessions</td>
                    <td>$0</td>
                    <td>Concession of 2%</td>
                    <td>- $3,000</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Date of Sale/Time</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>2024-07-25</td>
                    <td>2024-09-01</td>
                    <td>2024-06-30</td>
                    <td>2024-07-10</td>
                    <td>2024-08-05</td>
                    <td>2024-08-05</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Location</td>
                    <td>Downtown, City Center</td>
                    <td>Suburban, Northside</td>
                    <td>Urban, Central</td>
                    <td>Suburban, West End</td>
                    <td>Urban, Eastside</td>
                    <td>Rural, Countryside</td>
                    <td>Rural, Countryside</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Leasehold/Fee Simple</td>
                    <td>Fee Simple</td>
                    <td>Leasehold</td>
                    <td>Fee Simple</td>
                    <td>Fee Simple</td>
                    <td>Leasehold</td>
                    <td>Fee Simple</td>
                    <td>Fee Simple</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Site</td>
                    <td>0.5 acres, level</td>
                    <td>0.75 acres, sloping</td>
                    <td>1 acre, flat</td>
                    <td>0.3 acres, irregular</td>
                    <td>1.2 acres, cleared</td>
                    <td>0.8 acres, wooded</td>
                    <td>0.8 acres, wooded</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>View</td>
                    <td>City View</td>
                    <td>Mountain View</td>
                    <td>Ocean View</td>
                    <td>Park View</td>
                    <td>Lake View</td>
                    <td>No View</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Design (Style)</td>
                    <td>Modern</td>
                    <td>Colonial</td>
                    <td>Ranch</td>
                    <td>Contemporary</td>
                    <td>Victorian</td>
                    <td>Cottage</td>
                    <td>Cottage</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Quality of Construction</td>
                    <td>High</td>
                    <td>Average</td>
                    <td>Excellent</td>
                    <td>Good</td>
                    <td>Above Average</td>
                    <td>Fair</td>
                    <td>Fair</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Actual Age</td>
                    <td>10 Years</td>
                    <td>12 Years</td>
                    <td>8 Years</td>
                    <td>15 Years</td>
                    <td>20 Years</td>
                    <td>5 Years</td>
                    <td>5 Years</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Condition</td>
                    <td>Excellent</td>
                    <td>Good</td>
                    <td>Fair</td>
                    <td>Average</td>
                    <td>Above Average</td>
                    <td>Poor</td>
                    <td>Poor</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Above Grade</td>
                    <td> Total | Bdrms. | Baths </td>
                    <td> Total | Bdrms. | Baths </td>
                    <td>&nbsp;</td>
                    <td> Total | Bdrms. | Baths </td>
                    <td>&nbsp;</td>
                    <td> Total | Bdrms. | Baths </td>
                    <td>&nbsp;</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Room Count</td>
                    <td> 2 | 2 | 2 </td>
                    <td> 2 | 2 | 2 </td>
                    <td>&nbsp;</td>
                    <td> 2 | 2 | 2 </td>
                    <td>&nbsp;</td>
                    <td> 2 | 2 | 2 </td>
                    <td>&nbsp;</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Gross Living Area</td>
                    <td> 2323 sq. ft. </td>
                    <td> 2323 sq. ft. </td>
                    <td>&nbsp;</td>
                    <td> 2323 sq. ft. </td>
                    <td>&nbsp;</td>
                    <td> 2323 sq. ft. </td>
                    <td>&nbsp;</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Basement & Finished <br /> Rooms Below Grade</td>
                    <td>Full Basement, 2 Finished Rooms</td>
                    <td>Partial Basement, 1 Finished Room</td>
                    <td>No Basement</td>
                    <td>Full Basement, 3 Finished Rooms</td>
                    <td>Finished Basement, 1 Room</td>
                    <td>Full Basement, 4 Finished Rooms</td>
                    <td>Full Basement, 4 Finished Rooms</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Functional Utility</td>
                    <td>Fully Functional</td>
                    <td>Minor Modifications Needed</td>
                    <td>Fully Functional</td>
                    <td>Minor Modifications Needed</td>
                    <td>Fully Functional</td>
                    <td>Requires Major Renovations</td>
                    <td>Fully Functional</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Heating/Cooling</td>
                    <td>Central Heating, Central Air Conditioning</td>
                    <td>Forced Air Heating, Window AC</td>
                    <td>Radiant Heating, Central Air Conditioning</td>
                    <td>Forced Air Heating, Central Air Conditioning</td>
                    <td>Baseboard Heating, Window AC</td>
                    <td>Heat Pump, Central Air Conditioning</td>
                    <td>Central Heating, Window AC</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Energy Efficient Items</td>
                    <td>Energy Star Appliances, Solar Panels</td>
                    <td>Energy Star Appliances</td>
                    <td>Double Pane Windows, Insulation</td>
                    <td>Solar Panels, Energy Star Appliances</td>
                    <td>Double Pane Windows</td>
                    <td>LED Lighting, Energy Star Appliances</td>
                    <td>Solar Panels, Insulation</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Garage/Carport</td>
                    <td>2-Car Garage, Attached</td>
                    <td>1-Car Garage, Detached</td>
                    <td>Carport, 1-Car</td>
                    <td>2-Car Garage, Attached</td>
                    <td>1-Car Garage, Attached</td>
                    <td>1-Car Carport</td>
                    <td>2-Car Garage, Detached</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Porch/Patio/Deck</td>
                    <td>Front Porch, Rear Deck</td>
                    <td>Covered Porch, No Deck</td>
                    <td>Large Patio, No Porch</td>
                    <td>Front Porch, Back Deck</td>
                    <td>No Porch, Small Patio</td>
                    <td>Deck, No Porch</td>
                    <td>Front Porch, Small Deck</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Net Adjustment (Total)</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>☐ + ☐ -</td>
                    <td>$232,123</td>
                    <td>☐ + ☐ -</td>
                    <td>$232,123</td>
                    <td>☐ + ☐ -</td>
                    <td>$232,123</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Adjusted Sale Price of Comparables</td>
                    <td className='disabled_td'>&nbsp;</td>
                    <td>Net Adj. 6% <br /> Gross Adj. 5% </td>
                    <td>$232,123</td>
                    <td>Net Adj. 6% <br /> Gross Adj. 5% </td>
                    <td>$232,123</td>
                    <td>Net Adj. 6% <br /> Gross Adj. 5% </td>
                    <td>$232,123</td>
                </tr>
                <tr>
                    <td colSpan={9}>I ☐ did ☐ did not research the sale or transfer history of the subject property and comparable sales. If not, explain</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>My research ☐ did ☐ did not reveal any prior sales or transfers of the subject property for the three years prior to the effective date of this appraisal.</td>
                </tr>
                <tr>
                    <td colSpan={9}>Data source(s) </td>
                </tr>
                <tr>
                    <td colSpan={9}>My research ☐ did ☐ did not reveal any prior sales or transfers of the comparable sales for the year prior to the date of sale of the comparable sale. </td>
                </tr>
                <tr>
                    <td colSpan={9}>Data source(s) </td>
                </tr>
                <tr>
                    <td colSpan={9}>Report the results of the research and analysis of the prior sale or transfer history of the subject property and comparable sales (report additional prior sales on page 3).</td>
                </tr>
                <tr className='sales_table_tr sales_table_tr_head' >
                    <td colSpan={2}>ITME</td>
                    <td>SUBJECT</td>
                    <td colSpan={2}>COMPARABLE SALE #1</td>
                    <td colSpan={2}>COMPARABLE SALE #2</td>
                    <td colSpan={2}>COMPARABLE SALE #3</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Date of Prior Sale/Transfer</td>
                    <td>Jan 2022</td>
                    <td colSpan="2">Nov 2021</td>
                    <td colSpan="2">Oct 2020</td>
                    <td colSpan="2">Aug 2019</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Price of Prior Sale/Transfer</td>
                    <td>$350,000</td>
                    <td colSpan="2">$320,000</td>
                    <td colSpan="2">$300,000</td>
                    <td colSpan="2">$280,000</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Data Source(s)</td>
                    <td>MLS Listing</td>
                    <td colSpan="2">Agent/Owner</td>
                    <td colSpan="2">Public Records</td>
                    <td colSpan="2">Real Estate Website</td>
                </tr>
                <tr className='sales_table_tr'>
                    <td colSpan={2}>Effective Date of Data Source(s)</td>
                    <td>01/10/2024</td>
                    <td colSpan="2">12/15/2023</td>
                    <td colSpan="2">11/30/2023</td>
                    <td colSpan="2">01/05/2024</td>
                </tr>
                <tr>
                    <td colSpan={9}>Analysis of prior sale or transfer history of the subject property and comparable sale</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>Summary of Sales Comparison Approach</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={9}>Indicated Value by Sales Comparison Approach $877,965</td>
                </tr>
            </tbody>
        </table>
    )
}

export default ApSales